<template>
  <div :class="$vuetify.breakpoint.mdAndDown ? 'pt-2 pb-4' : 'pb-4'" v-if="!isIOS">
    <v-row no-gutters class="font-weight-bold hidden-md-and-up" align="end">
      <label>{{ $t(`label.downloadClient`) }}</label>
    </v-row>
    <v-row no-gutters justify="center" class="mx-0">
      <v-col cols="12" class="pt-2 download-banner">
        <v-img :src="`/static/image/banner/downloadClient/${currency}/${language}_bb88_downloadnow_appbanner_desktop.jpg`" class="hidden-sm-and-down banner-img" @click="downloadApk()"></v-img>
        <v-img :src="`/static/image/banner/downloadClient/${currency}/bb88_downloadnow_appbanner_mobile.jpg`" class="hidden-md-and-up banner-img" @click="downloadApk()"></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { uiHelper } from '@/util'
import { SHARED } from '@/constants/constants'

export default {
  name: 'appHomeDownloadClient',
  data: () => ({
    isIOS: uiHelper.IsIos(),
    currency: SHARED.DEFAULT_CURRENCY.toLowerCase(),
    language: uiHelper.getLanguage()
  }),
  methods: {
    downloadApk() {
      uiHelper.downloadAPK()
    }
  }
}
</script>

<style scoped>
.download-banner {
  cursor: pointer;
}

.banner-img {
  border-radius: 20px;
}
</style>
