<template>
  <div :class="$vuetify.breakpoint.mdAndDown ? 'pt-0 pb-4' : 'py-4'">
    <div v-if="matchesHighlights.length > 0">
      <v-row no-gutters class=" pb-4 matches-header" align="end">
        <v-col cols="10">
          {{ $t(`label.upComingMatches`) }}
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.lgAndUp" cols="2" class="text-right">
          <v-btn color="black" text height="auto" class="text-capitalize pr-0 body-2 text-decoration-underline" :to="{ name: routeName.CRICKET }">
            {{ $t(`button.more`) }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row class="pa-2">
      <!--        <v-col cols="12" class="bg-ambas d-flex pa-0">-->
      <v-col cols="12" :class="$vuetify.breakpoint.lgOnly ? 'tabHeight' : ''" class="pa-0 bg-ambas" v-if="isImageLoaded" :style="{ backgroundImage: 'url(' + currentBackgroundImage + ')' }">
        <v-col cols="12" class="col-md-7 ambas">
          <v-img :src="`/static/image/banner/ambas/BB88_mobile.png`" class="hidden-md-and-up banner-img" style="height: 100%; width: 100%;"></v-img>
          <div class="carousel-controls">
            <button v-for="(image, index) in images" :key="index" @click="selectImage(index)" :class="{ active: index === currentIndexes }" class="control-dot"></button>
          </div>
        </v-col>
        <v-col cols="12" class="align-center col-md-5 d-flex ambas-vid">
          <div class="iframe-box">
            <iframe
              :src="currentVideoSource"
              width="100%"
              height="100%"
              style="border: none; overflow: hidden; border-radius: 10px; aspect-ratio: 16/9"
              scrolling="no"
              frameborder="0"
              allowfullscreen="false"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
        </v-col>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="mx-0" v-if="matchesHighlights.length > 0">
      <!-- desktop view-->
      <!--      <v-col cols="12" class="pt-2 highlights-container-desktop hidden-sm-and-down">-->
      <!--        <div class="matches-box">-->
      <!--          <div class="match-item" v-for="(league, index) in matchesHighlights" :key="index">-->
      <!--            &lt;!&ndash; <v-img width="200" height="200" class="game-card-image-avatar" src="https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg" alt/> &ndash;&gt;-->
      <!--            <v-row no-gutters class="flex-nowrap">-->
      <!--              <v-col cols="12">-->
      <!--                <v-row no-gutters>-->
      <!--                  <v-col>-->
      <!--                    &lt;!&ndash; custom class for 1st and last to have border radius-->
      <!--                     :class="index == 0 ? 'border-top-left-radius' : index == matchesHighlights.length - 1 ? 'border-top-right-radius' : ''"-->
      <!--                     &ndash;&gt;-->
      <!--                    <v-card-->
      <!--                        class="pa-2 font-weight-bold cricket-name-header border-top-left-radius border-top-right-radius"-->
      <!--                        dark-->
      <!--                        outlined-->
      <!--                        tile-->
      <!--                    >-->
      <!--&lt;!&ndash;                      <v-icon dense class=" mb-1 mr-3">emoji_events</v-icon>&ndash;&gt;-->
      <!--                      <span style="color: #000;">{{ league.league_name }}</span>-->
      <!--                    </v-card>-->
      <!--                  </v-col>-->
      <!--                </v-row>-->
      <!--                <v-row no-gutters>-->
      <!--                  <v-col cols="9">-->
      <!--                    <v-card-->
      <!--                        light-->
      <!--                        class="pa-2 grey lighten-4"-->
      <!--                        outlined-->
      <!--                        tile-->
      <!--                    >-->
      <!--                      {{ league.home_team }}-->
      <!--                    </v-card>-->
      <!--                  </v-col>-->
      <!--                  <v-col cols="3">-->
      <!--                    <v-card-->
      <!--                        light-->
      <!--                        class="pa-2 grey lighten-4"-->
      <!--                        outlined-->
      <!--                        tile-->
      <!--                    >-->
      <!--                      {{ parseFloat(league.home_odds).toFixed(2) }}-->
      <!--                    </v-card>-->
      <!--                  </v-col>-->
      <!--                  <v-col cols="9">-->
      <!--                    <v-card-->
      <!--                        light-->
      <!--                        class="pa-2 grey lighten-4"-->
      <!--                        outlined-->
      <!--                        tile-->
      <!--                    >-->
      <!--                      {{ league.away_team }}-->
      <!--                    </v-card>-->
      <!--                  </v-col>-->
      <!--                  <v-col cols="3">-->
      <!--                    <v-card-->
      <!--                        light-->
      <!--                        class="pa-2 grey lighten-4"-->
      <!--                        outlined-->
      <!--                        tile-->
      <!--                    >-->
      <!--                      {{ parseFloat(league.away_odds).toFixed(2) }}-->
      <!--                    </v-card>-->
      <!--                  </v-col>-->
      <!--                </v-row>-->
      <!--                <v-row no-gutters>-->
      <!--                  <v-col cols="12">-->
      <!--                    <v-card-->
      <!--                        light-->
      <!--                        class="pa-2 grey lighten-4 border-bottom-left-radius border-bottom-right-radius"-->
      <!--                        outlined-->
      <!--                        tile-->
      <!--                    >-->
      <!--                      <v-row no-gutters>-->
      <!--                        <v-col cols="9">-->
      <!--                          {{ league.match_date }}-->
      <!--                        </v-col>-->
      <!--                        <v-col cols="3" class="text-right">-->
      <!--                          <v-btn color="black"-->
      <!--                                 class="primary-button embedded-mobile-login-button font-weight-bold yellow&#45;&#45;text"-->
      <!--                                 :to="{ name: routeName.CRICKET }">{{ $t(`button.betNow`) }}-->
      <!--                          </v-btn>-->
      <!--                        </v-col>-->
      <!--                      </v-row>-->
      <!--                    </v-card>-->
      <!--                  </v-col>-->
      <!--                </v-row>-->
      <!--              </v-col>-->
      <!--            </v-row>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </v-col>-->
      <v-row no-gutters class="align-center pt-4" v-if="!$vuetify.breakpoint.smAndDown">
        <div class="d-flex justify-center">
          <v-btn class="circle-btn" @click="scrollLeft" :disabled="!matchesHighlightsDesktop.showPrev">
            <v-icon color="#fff">mdi-chevron-left</v-icon>
          </v-btn>
        </div>
        <v-col cols="11" class="pt-2 highlights-container-desktop hidden-sm-and-down">
          <div class="matches-box">
            <div class="highlights-container-desktop" ref="container">
              <div class="item" v-for="(league, index) in matchesHighlightsDesktop.currentMatches" :key="index">
                <!-- Display match information here -->
                <v-row no-gutters class="flex-nowrap elevation-4" style="border-radius: 10px;">
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col>
                        <!-- custom class for 1st and last to have border radius
                         :class="index == 0 ? 'border-top-left-radius' : index == matchesHighlights.length - 1 ? 'border-top-right-radius' : ''"
                         -->
                        <v-card class="pa-2 font-weight-bold cricket-name-header border-top-left-radius border-top-right-radius" dark outlined tile>
                          <!--                      <v-icon dense class=" mb-1 mr-3">emoji_events</v-icon>-->
                          <span style="color: #000; font-size: 13px;">{{ league.league_name }} . {{ formatMatchDate(league.match_date) }}</span>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="pa-2 grey lighten-4 border-bottom-left-radius border-bottom-right-radius">
                      <v-col cols="9">
                        <v-card light class="pa-2 grey lighten-4 d-flex align-center" style="height: 100%; gap: 10px;" outlined tile>
                          <img :src="league.home_logo" height="25" width="25" />
                          {{ league.home_team }}
                        </v-card>
                      </v-col>
                      <!--                      <v-col cols="3">-->
                      <!--                        <v-card-->
                      <!--                          light-->
                      <!--                          class="pa-2 text-center grey lighten-4"-->
                      <!--                          style="height: 100%;"-->
                      <!--                          outlined-->
                      <!--                          tile-->
                      <!--                        >-->
                      <!--                          {{ parseFloat(league.home_odds).toFixed(2) }}-->
                      <!--                        </v-card>-->
                      <!--                      </v-col>-->
                      <v-col cols="9">
                        <v-card light class="pa-2 grey lighten-4 d-flex align-center" style="height: 100%; gap: 10px;" outlined tile>
                          <img :src="league.away_logo" height="25" width="25" />
                          {{ league.away_team }}
                        </v-card>
                      </v-col>
                      <!--                      <v-col cols="3">-->
                      <!--                        <v-card-->
                      <!--                          light-->
                      <!--                          class="pa-2 text-center grey lighten-4"-->
                      <!--                          style="height: 100%;"-->
                      <!--                          outlined-->
                      <!--                          tile-->
                      <!--                        >-->
                      <!--                          {{ parseFloat(league.away_odds).toFixed(2) }}-->
                      <!--                        </v-card>-->
                      <!--                      </v-col>-->
                    </v-row>
                    <!--                    <v-row no-gutters>-->
                    <!--                      <v-col cols="12">-->
                    <!--                        <v-card-->
                    <!--                          light-->
                    <!--                          class="pa-2 grey lighten-4 border-bottom-left-radius border-bottom-right-radius"-->
                    <!--                          outlined-->
                    <!--                          tile-->
                    <!--                        >-->
                    <!--                          <v-row no-gutters>-->
                    <!--                            <v-col cols="9">-->
                    <!--                              {{ league.match_date }}-->
                    <!--                            </v-col>-->
                    <!--                            <v-col cols="3" class="text-right">-->
                    <!--                              <v-btn color="black"-->
                    <!--                                     class="primary-button embedded-mobile-login-button font-weight-bold yellow&#45;&#45;text"-->
                    <!--                                     :to="{ name: routeName.CRICKET }">{{ $t(`button.betNow`) }}-->
                    <!--                              </v-btn>-->
                    <!--                            </v-col>-->
                    <!--                          </v-row>-->
                    <!--                        </v-card>-->
                    <!--                      </v-col>-->
                    <!--                    </v-row>-->
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
        <div class="d-flex justify-center">
          <v-btn class="circle-btn" @click="scrollRight" :disabled="!matchesHighlightsDesktop.showNext">
            <v-icon color="#fff">mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-row>
      <!-- mobile view-->
      <v-col cols="12" class="pt-2 highlights-container hidden-md-and-up">
        <div class="matches-box">
          <div class="match-item" v-for="(league, index) in matchesHighlights" :key="index">
            <!-- <v-img width="200" height="200" class="game-card-image-avatar" src="https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg" alt/> -->
            <v-row no-gutters class="flex-nowrap">
              <v-col cols="12">
                <v-card class="mobile-match-card" dark>
                  <v-row>
                    <v-col cols="12" class="ml-5 mt-2 font-weight-bold ">
                      {{ league.league_name }}
                    </v-col>
                  </v-row>
                  <v-row class="mobile-detail-wrapper no-gutters">
                    <v-col cols="4" class="mobile-match-details">
                      <v-row>
                        <v-col cols="12" class="d-flex align-center justify-center pb-0">
                          <img :src="league.home_logo" height="50" width="50" />
                        </v-col>
                        <v-col cols="12" class="pt-0 text-center">
                          <span class="team-title">{{ league.home_team }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4" class="mobile-match-details match-date">
                      {{ league.match_date }}
                    </v-col>
                    <v-col cols="4" class="mobile-match-details">
                      <v-row>
                        <v-col cols="12" class="d-flex align-center justify-center pb-0">
                          <img :src="league.away_logo" height="50" width="50" />
                        </v-col>
                        <v-col cols="12" class="pt-0 text-center">
                          <span class="team-title">{{ league.away_team }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
                <!--                <v-row no-gutters>-->
                <!--                 <v-col>-->
                <!--                   <v-card>-->
                <!--                    <v-row>-->
                <!--                      <v-col cols="4" class="mobile-match-details">-->
                <!--                        {{ league.home_team }}-->
                <!--                      </v-col>-->
                <!--                      <v-col cols="4" class="mobile-match-details">-->
                <!--                        {{ league.match_date }}-->
                <!--                      </v-col>-->
                <!--                      <v-col cols="4" class="mobile-match-details">-->
                <!--                        {{ league.away_team }}-->
                <!--                      </v-col>-->
                <!--                    </v-row>-->
                <!--                   </v-card>-->
                <!--                 </v-col>-->
                <!--                </v-row>-->
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import stingFormat from 'string-format'
import { formValidator, uiHelper } from '@/util'
import { SHARED } from '@/constants/constants'
import { GAME_MATCHES_HIGHLIGHTS } from '@/store/game.module'
import { ROUTE_NAME } from '@/constants/route.constants'
import { locale } from '@/util/localization-helper'
import { orderBy, take } from 'lodash'

export default {
  name: 'appHomeHighlight',
  data: () => ({
    isImageLoaded: false,
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    currentIndexes: 0,
    images: [
      {
        src: SHARED.MEDIA_SERVER_URL + '/banner/ambas/BB89_mobile.png',
        background: SHARED.MEDIA_SERVER_URL + '/banner/ambas/bb88_banner-GCJ.jpg',
        videoSource: 'https://www.youtube.com/embed/yV8OOraIC34?si=aa32ybr5_ptnxpjg'
      },
      {
        src: SHARED.MEDIA_SERVER_URL + '/banner/ambas/BB89_mobile.png',
        background: SHARED.MEDIA_SERVER_URL + '/banner/ambas/bb88_banner-CS.jpg',
        videoSource: 'https://www.youtube.com/embed/jsjsjwtS-_M?si=BMODOS7n6qms7HzT'
      },
      {
        src: SHARED.MEDIA_SERVER_URL + '/banner/ambas/BB89_mobile.png',
        background: SHARED.MEDIA_SERVER_URL + '/banner/ambas/bb88_banner-RK.jpg',
        videoSource: 'https://www.youtube.com/embed/wYOfcuueCpI?si=udBgUPbT6W6OK8qv'
      },
      {
        src: SHARED.MEDIA_SERVER_URL + '/banner/ambas/BB89_mobile.png',
        background: SHARED.MEDIA_SERVER_URL + '/banner/ambas/bb88_banner-AB.jpg',
        videoSource: 'https://www.youtube.com/embed/s1CYyQmbXcA?si=ZUpF4LFtAwAG8OlI'
      },
      {
        src: SHARED.MEDIA_SERVER_URL + '/banner/ambas/BB89_mobile.png',
        background: SHARED.MEDIA_SERVER_URL + '/banner/ambas/bb88_banner-MT.jpg',
        videoSource: 'https://www.youtube.com/embed/9opONcfJHi4?si=xKnTMolVo0kEkBUy'
      },
      {
        src: SHARED.MEDIA_SERVER_URL + '/banner/ambas/BB89_mobile.png',
        background: SHARED.MEDIA_SERVER_URL + '/banner/ambas/bb88_banner-NW.jpg',
        videoSource: 'https://www.youtube.com/embed/uHVsGorTAIo?si=WEWd8-bU65__Gxu7'
      }
      // {
      //   src: SHARED.MEDIA_SERVER_URL + '/banner/ambas/BB88_mobile.png',
      //   background: SHARED.MEDIA_SERVER_URL + '/banner/ambas/bb88_banner-DA.jpg',
      //   videoSource: 'https://www.youtube.com/embed/MYBQJZ-pBMk?si=eiQX-4ObsZDh1cHi'
      // }
      // Add more images as needed
    ],
    currentIndex: 0,
    itemsPerView: 3,
    highlights: null,
    routeName: ROUTE_NAME,
    desktopHighlights_1: [],
    desktopHighlights_2: [],
    currentHightlight_1: 0,
    currentHightlight_2: 0,
    startTime: SHARED.DEFAULT_START_TIME,
    endTime: SHARED.DEFAULT_END_TIME,
    currentCurrency: uiHelper.getCurrency()
  }),
  created() {
    if (this.currentCurrency != 'NPR') {
      this.getMatchesHighlights()
    }
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndexes].src
    },
    currentBackgroundImage() {
      return this.images[this.currentIndexes].background
    },
    currentVideoSource() {
      return this.images[this.currentIndexes].videoSource
    },
    matchesHighlightsDesktop() {
      let filteredMatches = this.$store.state.game.matchesHighlights.filter(x => x.match_date >= this.startTime)
      filteredMatches = orderBy(filteredMatches, ['match_date'], ['asc'])
      const matchesPerPage = 3
      let startIndex = this.currentIndex * matchesPerPage
      let endIndex = startIndex + matchesPerPage
      const currentMatches = filteredMatches.slice(startIndex, endIndex)

      // Update button visibility
      const totalPages = Math.ceil(filteredMatches.length / matchesPerPage)
      const showNext = this.currentIndex < totalPages - 1
      const showPrev = this.currentIndex > 0

      return {
        currentMatches,
        showNext,
        showPrev
      }
    },
    matchesHighlights() {
      if (this.$store.state.game.matchesHighlights && this.$store.state.game.matchesHighlights.length > 0) {
        let k = this.$store.state.game.matchesHighlights.filter(x => x.match_date >= this.startTime)
        k = orderBy(k, ['match_date'], ['asc'])
        k = take(k, 7)
        const n = Math.ceil(k.length / 2)
        this.desktopHighlights_1 = k.slice(0, n)
        this.desktopHighlights_2 = k.slice(n)
        k = k.map(function(league) {
          let leagueTime = league.match_date.indexOf(' ') != -1 ? league.match_date.split(' ')[1] : league.match_date
          let leagueDate = league.match_date.indexOf(' ') != -1 ? league.match_date.split(' ')[0] : league.match_date

          league.matchTime = leagueTime.split(':')[0] + ':' + leagueTime.split(':')[1]
          league.matchDate = leagueDate

          return league
        })
        return k
      }

      return []
    }
  },
  /*watch: {
    matchesHighlights() {
      this.highlights = this.$store.state.game.matchesHighlights
      return this.highlights
    }
  },*/
  mounted() {
    this.startImageRotation()
  },
  methods: {
    selectImage(index) {
      this.currentIndexes = index
    },
    startImageRotation() {
      this.isImageLoaded = true
      setInterval(() => {
        if (this.images.length > 0) {
          this.currentIndexes = (this.currentIndexes + 1) % this.images.length
        }
        if (this.currentIndexes >= this.images.length) {
          this.currentIndexes = 0 // Reset currentIndex to 0 if it exceeds the length of images array
        }
      }, 50000)
    },
    formatMatchDate(dateString) {
      const [datePart, timePart] = dateString.split(' ')
      const [year, month, day] = datePart.split('-')
      const [hour, minute] = timePart.split(':')

      const date = new Date(year, month - 1, day, hour, minute)
      const today = new Date()

      // Check if the date is today
      if (date.toDateString() === today.toDateString()) {
        // If it's today, return 'Today'
        return 'Today , ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      } else {
        // If it's not today, return the date and time
        return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' }) + ',' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      }
    },
    changeLanguage(language) {
      this.$store.dispatch(`${SHARED.LOADING}`)
      uiHelper.removeCookie('language')
      uiHelper.setCookie('language', language)
      window.location.reload()
    },
    getMatchesHighlights() {
      this.$store.dispatch(`${GAME_MATCHES_HIGHLIGHTS}`)
    },
    scrollLeft() {
      this.currentIndex--
    },
    scrollRight() {
      this.currentIndex++
    }
  }
}
</script>

<style lang="scss">
.tabHeight {
  height: 320px !important;
}
.carousel-controls {
  position: absolute;
  bottom: 55px;
  left: 15px;
}

.control-dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #d9d9d9; /* Default color */
  margin-left: 5px; /* Adjust as needed */
}

.control-dot.active {
  background-color: #ffce01; /* Active color */
}
// .v-card {
//     border-radius: 5px 0 0 5px !important;
// }
.circle-btn {
  border-radius: 50%; /* Make the button round */
  min-width: 40px !important;
  min-height: 40px !important;
  width: 40px; /* Adjust the size as needed */
  height: 40px; /* Adjust the size as needed */
  background-color: #0080f6 !important; /* Change the background color as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}
.item {
  display: inline-block;
  padding: 10px;
  width: calc(100% / 3); /* Each item occupies 1/3 of the viewport width */
  /* Adjust styling for each item as needed */
}
.highlights-container-desktop {
  //overflow-x: scroll;
  //overflow-y: hidden;
  //white-space: nowrap;
  //max-width: 100vw;

  .border-top-left-radius {
    border-top-left-radius: 10px !important;
  }

  .border-top-right-radius {
    border-top-right-radius: 10px !important;
  }

  .border-bottom-left-radius {
    border-bottom-left-radius: 10px !important;
  }

  .border-bottom-right-radius {
    border-bottom-right-radius: 10px !important;
  }
}
.highlights-container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 100vw;

  .border-top-left-radius {
    border-top-left-radius: 10px !important;
  }

  .border-top-right-radius {
    border-top-right-radius: 10px !important;
  }

  .border-bottom-left-radius {
    border-bottom-left-radius: 10px !important;
  }

  .border-bottom-right-radius {
    border-bottom-right-radius: 10px !important;
  }
}

.matches-box {
  margin-bottom: 5px;

  .match-item {
    display: inline-block;
    width: 360px; // padding-right: 20px;
    margin: 0 15px 0 0;
  }

  .mobile-match-card {
    &.v-card {
      text-overflow: ellipsis;
      overflow: hidden;
      width: auto;
      white-space: nowrap;
      background-color: #1d1d1d;
      border-radius: 15px;
      color: #ffffff;
    }
  }

  .mobile-detail-wrapper {
    background-color: #333333;
    height: auto;
    min-height: 120px;
    display: flex;
    align-items: center;

    .mobile-match-details {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .team-title {
      white-space: break-spaces;
      overflow: hidden;
      padding: 0 5px 0 5px;
      text-align: center;
    }

    .match-date {
      white-space: pre-line;
      text-overflow: ellipsis;
      text-align: center;
      align-items: unset;
      color: var(--v-newMobilePrimary-base);
      height: 78px;
    }
  }
}

@media (max-width: 599px) {
  .highlights-container {
    font-size: 14px;
  }

  .matches-box {
    .match-item {
      width: 300px;
    }
  }
}

.cricket-name-header {
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  white-space: nowrap;
  background-color: #ffce01 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

@media screen and (min-width: 900px) {
  .matches-header {
    font-size: 25px;
  }
}
</style>
