<template>
  <div class="bg_home">
    <app-home-banner :filteredAnnouncement="filteredAnnouncement"></app-home-banner>
    <div class="bg_filter">
      <app-member-balance :parent="this.routeName.HOME"></app-member-balance>
      <app-game-tab-holder></app-game-tab-holder>
      <app-home-highlight></app-home-highlight>
      <app-referral></app-referral>
      <app-download-client></app-download-client>
      <v-row no-gutters v-if="$vuetify.breakpoint.mdAndDown"></v-row>
      <!-- <v-row justify="center" align="center" class="px-8" no-gutters v-if="$vuetify.breakpoint.mdAndDown">
  <v-col cols="3" class="text-center">
    <v-btn @click="selectionType = 'all'" min-height="60" :elevation="0"
           :color="selectionType == 'all' ? 'primary' : 'transparent'">
      <v-row no-gutters>
        <v-col cols="12">
          <nav-all class="icon_gameHome"></nav-all>
        </v-col>
        <v-col cols="12">
          {{ $t(`label.all`) }}
        </v-col>
      </v-row>
    </v-btn>
  </v-col>
  <v-col cols="3" class="text-center">
    <v-btn @click="selectionType = 'rng'" min-height="60" :elevation="0"
           :color="selectionType == 'rng' ? 'primary' : 'transparent'">
      <v-row no-gutters>
        <v-col cols="12">
          <nav-slot class="icon_gameHome"></nav-slot>
        </v-col>
        <v-col cols="12">
          {{ $t(`label.rng_home`) }}
        </v-col>
      </v-row>
    </v-btn>
  </v-col>
  <v-col cols="3" class="text-center">
    <v-btn @click="selectionType = 'casino'" min-height="60" :elevation="0"
           :color="selectionType == 'casino' ? 'primary' : 'transparent'">
      <v-row no-gutters>
        <v-col cols="12">
          <nav-casino class="icon_gameHome"></nav-casino>
        </v-col>
        <v-col cols="12">
          {{ $t(`label.ld_home`) }}
        </v-col>
      </v-row>
    </v-btn>
  </v-col>
  <v-col cols="3" class="text-center">
    <v-btn @click="selectionType = 'sports'" min-height="60" :elevation="0"
           :color="selectionType == 'sports' ? 'primary' : 'transparent'">
      <v-row no-gutters>
        <v-col cols="12">
          <nav-sports class="icon_gameHome"></nav-sports>
        </v-col>
        <v-col cols="12">
          {{ $t(`label.sb_home`) }}
        </v-col>
      </v-row>
    </v-btn>
  </v-col>
</v-row>
<v-row v-if="$vuetify.breakpoint.mdAndDown">
  <v-col cols="12" class="px-12 pt-0">
    <v-divider></v-divider>
  </v-col>
</v-row> -->
      <!-- <app-game-card :openLoginDialog="this.openLoginDialog" :gameType="'rng'"
                     v-show="selectionType=='all' || selectionType == 'rng' || $vuetify.breakpoint.lgAndUp"
                     :selectionNavigator="selectionType"></app-game-card>
      <app-game-card :openLoginDialog="this.openLoginDialog" :gameType="'ld'"
                     v-show="selectionType=='all' || selectionType == 'casino' || $vuetify.breakpoint.lgAndUp"
                     :selectionNavigator="selectionType"></app-game-card> -->
      <!-- <app-sport-casino-holder class="hidden-lg-and-up"
                               v-show="selectionType=='all' || selectionType == 'sports' || $vuetify.breakpoint.lgAndUp"></app-sport-casino-holder> -->
      <!-- <v-row no-gutters justify="center" align="center">
        <v-col cols="12" :lg="4" class="px-6">
          <hooper style="height: 470px;" :touchDrag="true"
                  :mouseDrag="true" :wheelControl="false">
            <slide>
              <recent-deposit :isLogin="isLoggedIn"></recent-deposit>
            </slide>
            <slide>
              <recent-withdrawal></recent-withdrawal>
            </slide>
            <hooper-pagination slot="hooper-addons"></hooper-pagination>
          </hooper>
        </v-col>
        <v-col cols="12" :lg="4" :class="['px-6', $vuetify.breakpoint.mdAndDown ? 'my-10' : 'my-0']">
          <recent-winner></recent-winner>
        </v-col>
      </v-row> -->
      <!--      <v-col cols="12" v-if="$vuetify.breakpoint.mdAndUp" :class="'px-5 text-left'">-->
      <!--        <h1>{{ $t(`footer.trustedPremiumOnlineCasinoTitle`) }}</h1>-->
      <!--        <h3>{{ $t(`footer.trustedPremiumOnlineCasinoDesc_1`) }}</h3>-->
      <!--      </v-col>-->
      <v-row class="fill-height" justify="center">
        <v-divider vertical></v-divider>
      </v-row>
      <!--      <div class="coin_box_gif" v-if="showCoin && configuration.coin_grab.enabled == true">-->
      <!--        <div style="position:relative">-->
      <!--          <v-btn @click="dontShowMeAgain()" outlined x-small absolute right rounded class="px-0" color="black" style="min-width: 20px; top:-15px; right:-5px;">-->
      <!--            <v-icon size="14px">close</v-icon>-->
      <!--          </v-btn>-->
      <!--          <img :src="grabCoin.home_gif" :aspect-ratio="200 / 200" width="65" height="65" class="grab_coin_icon" @click="navigateToCoinPage()" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div v-if="configuration.spin_wheel.enabled == true">
        <v-img :src="`/static/image/wof/wofSpin.gif`" :aspect-ratio="489 / 516" width="72" height="70" class="wofClass" @click="navigateWOFPage()"></v-img>
      </div>
      <div v-if="configuration.card_mania.enabled == true">
        <v-img :src="`/static/image/cardMania/icon-card-mania.gif`" :aspect-ratio="489 / 516" width="72" height="70" class="cardManiaClass" @click="navigateCardMania()"></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import { DevicePlatform } from '@/constants/enums'
import { ROUTE_NAME } from '@/constants/route.constants'
import AppHomeBanner from '@/components/home/HomeBanner.vue'
import RecentWinner from '@/components/home/RecentWinner'
import RecentWithdrawal from '@/components/home/RecentWithdrawal'
import RecentDeposit from '@/components/home/RecentDeposit'
import AppPlayWithUs from '@/components/home/PlayWithUs.vue'
import { AnnouncementType } from '@/constants/enums'
import { uiHelper, locale } from '@/util'
import { ANNOUNCEMENT_LIST } from '@/store/announcement.module'
import { SHARED, CACHE_KEY, SESSION } from '@/constants/constants'
import AppSportCasinoHolder from '@/components/home/SportCasinoHolder.vue'
import NavSlot from '@/assets/svg/rng_icon.svg'
import NavCasino from '@/assets/svg/ld_icon.svg'
import NavAll from '@/assets/svg/all_icon.svg'
import NavSports from '@/assets/svg/sportsbook_icon.svg'
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper'
import { MEMBER_DETAIL } from '@/store/member.module'
import AppHomeHighlight from '@/components/home/Highlight.vue'
import AppGameTabHolder from '@/components/home/GameTabHolder.vue'
import AppReferral from '@/components/member/referral'
import { MULTIPLEWHEEL_MODULE, WHEEL_MODULE } from '@/store/wheel.module'
import { GRAB_COIN_SETTING } from '@/store/bonus.module'
import { REWARD_CONFIGURATION_STATUS } from '@/store/reward.module'
import AppDownloadClient from '@/components/home/DownloadClient.vue'
import AppMemberBalance from '@/components/member/MemberBalance.vue'
import { SHARED_LOADING } from '@/store/shared.module'
import { orderBy } from 'lodash'

export default {
  name: 'Home',
  metaInfo: {
    title: `${locale.getMessage('meta.home_title')}`,
    titleTemplate: `%s | ${uiHelper.getHost()}`,
    meta: [
      { charset: 'utf-8' },
      {
        name: 'description',
        content: `${locale.getMessage('meta.home_description')}`
      },
      { name: 'keyword', content: `${locale.getMessage('meta.home_keyword')}` }
    ]
  },
  components: {
    Hooper,
    Slide,
    HooperPagination,
    RecentWinner,
    AppHomeBanner,
    AppPlayWithUs,
    RecentDeposit,
    RecentWithdrawal,
    AppSportCasinoHolder,
    NavSlot,
    NavCasino,
    NavAll,
    NavSports,
    AppHomeHighlight,
    AppGameTabHolder,
    AppReferral,
    AppDownloadClient,
    AppMemberBalance
  },
  data: () => ({
    showCoin: true,
    selectionType: 'all',
    storageAnnouncement: null,
    currentLanguage: uiHelper.getLanguage(),
    filteredAnnouncement: [],
    isIOS: uiHelper.IsIos(),
    routeName: ROUTE_NAME,
    languageList: [
      {
        currency: 'BDT',
        currencyCd: '৳',
        img: 'BDT',
        language: [
          {
            title: 'English',
            cd: 'en'
          },
          {
            title: 'Bengali',
            cd: 'bd'
          }
        ]
      },
      {
        currency: 'INR',
        currencyCd: '₹',
        img: 'INR',
        language: [
          {
            title: 'English',
            cd: 'enin'
          },
          {
            title: 'Hindi',
            cd: 'hi'
          }
        ]
      },
      {
        currency: 'NPR',
        currencyCd: 'रू',
        img: 'INR',
        language: [
          {
            title: 'English',
            cd: 'enne'
          },
          {
            title: 'Nepalese',
            cd: 'ne'
          }
        ]
      }
    ]
  }),
  computed: {
    matchesHighlightsTest() {
      let filteredMatches = this.$store.state.game.matchesHighlights.filter(x => x.match_date >= this.startTime)
      filteredMatches = orderBy(filteredMatches, ['match_date'], ['asc'])
      const matchesPerPage = 3
      let startIndex = this.currentIndex * matchesPerPage
      let endIndex = startIndex + matchesPerPage
      const currentMatches = filteredMatches.slice(startIndex, endIndex)

      // Update button visibility
      const totalPages = Math.ceil(filteredMatches.length / matchesPerPage)
      const showNext = this.currentIndex < totalPages - 1
      const showPrev = this.currentIndex > 0

      return {
        currentMatches,
        showNext,
        showPrev
      }
    },
    grabCoin() {
      let d = this.$store.state.bonus.grabCoinSettings
      return d
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    announcementList() {
      return this.$store.state.announcement.announcements
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    memberWallet() {
      return this.$store.state.member.walletBalance
    },
    configuration() {
      let d = this.$store.state.reward.configurationStatus
      return d
    }
  },
  watch: {
    configuration() {
      let d = this.$store.state.reward.configurationStatus
      if (d.coin_grab.enabled) {
        this.getGrabCoinSetting()
      }
    },
    announcementList() {
      this.storageAnnouncement = this.$store.state.announcement.announcements
      this.populateAnnouncement(this.storageAnnouncement)
    }
  },
  created() {
    // let language = 'enin'
    // locale.setCurrentLanguage(language)
    // uiHelper.setCookie('language', language)
    // console.log('homepage title language', locale.getMessage('meta.home_title'))
    let reload = this.changeCurrency()

    this.checkShouldHideCoin()
    // this.getWOF()
    this.getMultiWOF()
    this.checkIncomingAction()
    this.getAnnouncement()
    this.getMemberDetail()
    this.checkReferCode(reload)
    this.getConfigurationStatus()
    this.changeMetaTag()
  },
  methods: {
    changeMetaTag() {
      let title = locale.getMessage(`meta.home_title`)
      let desc = locale.getMessage(`meta.home_description`)
      let keywords = locale.getMessage(`meta.home_keyword`)
      uiHelper.setMetaTag(title, desc, keywords, true)
      const currentUrl = window.location.href
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      const Linksenin = `https://${domain}/en-IN${cleanPath}`
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    },
    changeCurrency() {
      let lang = this.$route.query.lang
      let cur = this.$route.query.cur
      let af = this.$route.query.af

      if (lang && cur) {
        cur = cur.toUpperCase()
        lang = lang.toLowerCase()
        /*check existing lang & cur , yes/same  = no reload ; no/not same as default : reload*/
        let currentLang = uiHelper.getLanguage()
        let currentCur = uiHelper.getCurrency()
        if (currentLang === lang && currentCur === cur) {
          return true
        } else {
          let curObj = this.languageList.filter(obj => obj.currency === cur)

          if (curObj && curObj.length > 0) {
            let langObj = curObj[0].language.filter(a => a.cd === lang)
            // .map(obj => ({ ...obj, language: obj.language.filter(c => c.cd === lang) }))
            // .filter(obj => obj.language.length > 0)
            if (langObj && langObj.length > 0) {
              uiHelper.removeCookie('language')
              uiHelper.setCookie('language', langObj[0].cd)
              locale.setCurrentLanguage(langObj[0].cd)
              uiHelper.removeCookie('changeLanguage')
              uiHelper.setCookie('changeLanguage', true)
              uiHelper.setCurrency(cur)

              let url = window.location.href.split('?')[0]
              window.location.href = url + '?af=' + af
            }
          }
        }
      }
    },
    getConfigurationStatus() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${REWARD_CONFIGURATION_STATUS}`, { filter })
    },
    checkReferCode(isSameCurLang) {
      let r = this.$route.query.refer_code
      let af = this.$route.query.af
      let u_s = this.$route.query.utm_source
      let u_m = this.$route.query.utm_medium
      let u_c = this.$route.query.utm_campaign
      let s2 = this.$route.query.s2
      let gnClickId = this.$route.query.click_id //grindclickid
      let lang = this.$route.query.lang
      let cur = this.$route.query.cur
      let uid = this.$route.query.uid //clickid
      let pid = this.$route.query.sub1
      let ab_click_id = this.$route.query.ref_id
      let stag = this.$route.query.stag
      let tracking_link = this.$route.query.tracking_link
      let no_reg = this.$route.query.noreg

      try {
        let old_af = uiHelper.getCookie(SESSION.AFF_CODE)
        if (typeof old_af != 'undefined' && old_af != '' && old_af != null) {
          if (old_af !== af) {
            //if old af not match clear all pixel info.
            uiHelper.removeCookie(SESSION.PID)
            uiHelper.removeCookie(SESSION.CLICKID)
            uiHelper.removeCookie(SESSION.UTM_SOURCE)
            uiHelper.removeCookie(SESSION.UTM_MEDIUM)
            uiHelper.removeCookie(SESSION.UTM_CAMPAIGN)
            uiHelper.removeCookie(SESSION.UTM_S2)
            uiHelper.removeCookie(SESSION.GN_CLICKID)
          }
        }
      } catch (err) {
        console.log(err)
      }

      if (typeof stag != 'undefined' && stag != '' && stag != null) {
        uiHelper.setCookie(SESSION.STAG, stag, 1)
      }
      if (typeof tracking_link != 'undefined' && tracking_link != '' && tracking_link != null) {
        uiHelper.setCookie(SESSION.TRACKING_LINK, tracking_link, 1)
      }

      if (typeof pid != 'undefined' && pid != '' && pid != null) {
        uiHelper.setCookie(SESSION.PID, pid, 1)
      }

      if (typeof uid != 'undefined' && uid != '' && uid != null) {
        uiHelper.setCookie(SESSION.CLICKID, uid, 1)
      }

      if (typeof ab_click_id != 'undefined' && ab_click_id != '' && ab_click_id != null) {
        uiHelper.setCookie(SESSION.CLICKID, ab_click_id, 1)
      }

      if (typeof gnClickId != 'undefined' && gnClickId != '' && gnClickId != null) {
        uiHelper.setCookie(SESSION.GN_CLICKID, gnClickId, 1)
      }

      if (typeof u_s != 'undefined' && u_s != '' && u_s != null) {
        uiHelper.setCookie(SESSION.UTM_SOURCE, u_s, 1)
      }
      if (typeof u_m != 'undefined' && u_m != '' && u_m != null) {
        uiHelper.setCookie(SESSION.UTM_MEDIUM, u_m, 1)
      }
      if (typeof u_c != 'undefined' && u_c != '' && u_c != null) {
        uiHelper.setCookie(SESSION.UTM_CAMPAIGN, u_c, 1)
      }
      if (typeof s2 != 'undefined' && s2 != '' && s2 != null) {
        uiHelper.setCookie(SESSION.UTM_S2, s2, 1)
      }
      if (lang && cur && af && !isSameCurLang) {
        /*skip open */
        return
      } else {
        if (typeof af != 'undefined' && af != '' && af != null) {
          uiHelper.setCookie(SESSION.AFF_CODE, af, 365)
          console.log('openDialog', '1')
          if (no_reg != 'true') {
            this.goToRegisterPage()
          }
        } else {
          if (typeof r != 'undefined' && r != '' && r != null) {
            uiHelper.setCookie(SESSION.REFER_CODE, r, 365)
            console.log('openDialog', '2')
            this.goToRegisterPage()
          }
        }
      }
    },
    getGrabCoinSetting() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${GRAB_COIN_SETTING}`, { filter })
    },
    checkShouldHideCoin() {
      if (typeof uiHelper.getCookie(SESSION.HIDE_COIN) != 'undefined' && uiHelper.getCookie(SESSION.HIDE_COIN) == 1) {
        this.showCoin = false
      } else {
        this.showCoin = true
      }
    },
    dontShowMeAgain() {
      this.showCoin = false
      uiHelper.setCookie(SESSION.HIDE_COIN, 1, 0.5)
    },
    // navigateToCoinPage() {
    //   this.$router.push({
    //     name: ROUTE_NAME.GRAB_COIN,
    //     params: {
    //       type: 'coin',
    //       lang: this.$route.params.lang
    //     }
    //   })
    // },
    navigateWOFPage() {
      let multiWheel = this.$store.state.wheel.multipleWheel

      if (uiHelper.getPlatform() == DevicePlatform.DESKTOP) {
        if (multiWheel != null && multiWheel.length <= 1) {
          this.$router.push({ name: ROUTE_NAME.SingleWOF, params: { lang: this.$route.params.lang } })
        } else {
          this.$router.push({
            name: ROUTE_NAME.WOF,
            params: { lang: this.$route.params.lang }
          })
        }
      } else {
        if (multiWheel != null && multiWheel.length <= 1) {
          this.$router.push({ name: ROUTE_NAME.SingleMWOF, params: { lang: this.$route.params.lang } })
        } else {
          this.$router.push({
            name: ROUTE_NAME.MWOF,
            params: { lang: this.$route.params.lang }
          })
        }
      }
    },
    navigateCardMania() {
      this.$router.push({
        name: ROUTE_NAME.CARD_MANIA,
        params: { lang: this.$route.params.lang }
      })
    },
    getWOF() {
      let currency = uiHelper.getCurrency()
      let filter = {
        currency: currency
      }
      this.$store.dispatch(`${WHEEL_MODULE}`, { filter })
    },
    getMultiWOF() {
      let currency = uiHelper.getCurrency()
      let filter = {
        currency: currency
      }
      this.$store.dispatch(`${MULTIPLEWHEEL_MODULE}`, { filter })
    },
    getMemberDetail() {
      if (this.isLoggedIn) this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    goToAffilateProfilePage() {
      this.$router.push({
        name: ROUTE_NAME.AFFILIATE_LINK,
        lang: this.$route.params.lang
      })
    },
    goToAffilateHomePage() {
      this.$router.push({
        name: ROUTE_NAME.AFFILIATE_HOME
      })
    },
    goToDepositPage() {
      if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
        let routeData = this.$router.resolve({
          name: ROUTE_NAME.DEPOSIT
        })
        window.open(routeData.href)
      } else {
        this.$router.push({
          name: ROUTE_NAME.DEPOSIT,
          lang: this.$route.params.lang
        })
      }
    },
    getAnnouncement() {
      if (this.storageAnnouncement == null) {
        let obj = {
          site: uiHelper.getPlatform(),
          type: AnnouncementType.ALL,
          page: '',
          itemsPerPage: ''
        }
        this.$store.dispatch(`${ANNOUNCEMENT_LIST}`, { obj })
      } else {
        this.populateAnnouncement(this.storageAnnouncement)
      }
    },
    populateAnnouncement(announcementData) {
      let p = []
      announcementData.forEach(announcement => {
        p.push({
          id: announcement.id,
          date: announcement.announcementDate,
          title:
            announcement.titleLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.titleLocale.find(x => x.language == this.currentLanguage).title
              : announcement.titleLocale.find(x => x.language == 'en') != null
              ? announcement.titleLocale.find(x => x.language == 'en').title
              : announcement.titleLocale[0].title,
          message:
            announcement.messageLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.messageLocale.find(x => x.language == this.currentLanguage).message
              : announcement.messageLocale.find(x => x.language == 'en') != null
              ? announcement.messageLocale.find(x => x.language == 'en').message
              : announcement.messageLocale[0].message
        })
      })
      this.filteredAnnouncement = p
    },
    checkIncomingAction() {
      let action = this.$route.params.action

      if (action == SHARED.RESET_PASSWORD) {
        let memberCode = this.$route.params.memberCode
        let contact = this.$route.params.contact
        if (memberCode != null && memberCode != undefined && contact != null && contact != undefined) this.$parent.openResetPasswordDialog(memberCode, contact)
      } else if (action == SHARED.INFO_VERIFICATION) {
        let memberCode = this.$route.params.memberCode
        let otp = this.$route.params.otp
        let type = this.$route.params.type
        let contact = this.$route.params.contact
        if (memberCode != null && memberCode != undefined && otp != null && otp != undefined && type != null && type != undefined)
          this.$parent.memberContactVerification(memberCode, otp, type, contact)
      } else if (action == SHARED.REGISTER) {
        console.log('openDialog', '3')
        this.goToRegisterPage()
      }
    },
    openRegisterDialog() {
      this.$parent.openRegisterDialog()
    },
    openLoginDialog() {
      this.$parent.openLoginDialog()
    },
    goToRegisterPage() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER,
        params: { lang: this.$route.params.lang }
      })
    },
    goToLoginPage() {
      this.$router.push({
        name: ROUTE_NAME.LOGIN,
        params: { lang: this.$route.params.lang }
      })
    }
  }
}
</script>
<style lang="scss">
.bg-ambas {
  border-radius: 20px;
  background-size: cover;
  display: flex;
  //background-position: center;
  height: 100%;
  width: 100%;
  //box-shadow: 0px 3px 1px -2px rgba(0,0,0,.2), 0px 2px 2px 0px rgba(0,0,0,.14), 0px 1px 5px 0px rgba(0,0,0,.12);
}

.bg-ambasM {
  //background-image: url('/static/image/banner/ambas/BB88_mobile.png');
  border-radius: 20px;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.ambas {
  padding-bottom: 0;
  margin-bottom: -50px;
  position: relative;
  z-index: 1;
}

.ambas-vid {
  position: relative;
  z-index: 2;
}

.iframe-box {
  width: 100%;
}

.active_click {
  background-color: white;
}

.icon_gameHome {
  fill: white;
  color: black;
  stroke: #9c9ff3;
  width: 40px;
  height: 33px;
  padding-top: 4px;
}

.hooper-pagination {
  position: absolute;
  bottom: -6%;
  right: 52%;
  transform: translateX(52%);
  display: flex;
  padding: 5px 10px;

  ol.hooper-indicator {
    padding-left: 0;
  }
}

.hooper-indicator {
  width: 15px;
  height: 15px;
  border-radius: 10px;
}

.bg_home {
  // background-image: url('/static/image/background/home_page_bg.jpg');
  background-color: #fff;
  // background-position: 0 -300px;
}

.bg_filter {
  padding: 1% 12% 3% 12%;
}

.theme--light {
  &.v-application {
    background-color: #333333 !important;
  }
}

.partner-box {
  height: 450px;
  position: relative;

  .partner-row {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;

    .partner-col {
      position: relative;

      .partner-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .partner-desc {
        border-bottom: 4px solid var(--v-primary-base);
      }

      .partner-box-trophy {
        position: absolute;
        bottom: 0;
        right: 600px;
      }

      .partner-box-footballer {
        position: absolute;
        bottom: 0px;
        height: 420px;
        right: 100px;
        width: 800px;
      }
    }
  }
}

.joinInTheFun {
  border-bottom: 2px solid;
  border-color: var(--v-secondary-base);
  border-radius: 0px !important;
  cursor: pointer;
  color: var(--v-secondary-base);
  font-size: 16px;

  &:hover {
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);
  }
}

.announcement-marquee-box {
  max-width: 1840px;
}

.home-announcment-content {
  p {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 1650px) {
  .partner-box {
    .partner-row {
      .partner-col {
        .partner-box-trophy {
          right: 550px;
        }

        .partner-box-footballer {
          height: 380px;
          right: 80px;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .partner-box {
    .partner-row {
      .partner-col {
        .partner-box-trophy {
          right: 360px;
          width: 280px;
        }

        .partner-box-footballer {
          height: auto;
          right: 50px;
          width: 600px;
        }
      }
    }
  }

  .joinInTheFun,
  .home-announcment-title,
  .home-announcment-content p {
    font-size: 15px;
  }
}

@media (max-width: 1350px) {
  .partner-box {
    height: 340px;

    .partner-row {
      .partner-col {
        .partner-title {
          width: 300px;
        }

        .partner-box-footballer {
          width: 500px;
        }

        .partner-box-trophy {
          right: 275px;
          width: 220px;
        }
      }
    }
  }
}

@media (max-width: 1263px) {
  .partner-box .partner-row .partner-col .partner-desc {
    font-size: 26px;
  }
  .joinInTheFun,
  .home-announcment-title,
  .home-announcment-content p {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .partner-box .partner-row .partner-col .partner-desc {
    font-size: 24px;
  }
}

@media (max-width: 959px) {
  .bg-ambas {
    display: block !important;
    background-image: none !important;
  }

  .bg_filter {
    background-color: white;
    padding: 12px;
  }

  .partner-box {
    height: 600px;
  }

  .partner-box .partner-row .partner-col.partner-box-message {
    height: 300px;
  }
  .partner-box .partner-row .partner-col.partner-box-image {
    height: 300px;
  }
}

@media (max-width: 599px) {
  .hooper-pagination {
    bottom: -3%;
  }

  .bg_home {
    .bg_filter {
      padding: 3% 3%;
    }
  }
  .partner-box {
    height: 300px;
  }
  .partner-box .partner-row .partner-col.partner-box-image {
    display: none;
  }
  .joinInTheFun,
  .home-announcment-title,
  .home-announcment-content p {
    font-size: 13px;
  }
}

@media (max-width: 425px) {
  .hooper-pagination {
    bottom: -5%;
  }
  // .v-badge__badge {
  //   bottom: 8px !important;
  // }
  .partner-box .partner-row .partner-col .partner-title {
    width: 220px;
  }
}

.wofClass {
  bottom: 20%;
  right: 0.5%;
  z-index: 1 !important;
  cursor: pointer;
  position: fixed !important;
}

.cardManiaClass {
  //<<<<<<< HEAD
  //  bottom: 11%;
  //=======
  bottom: 28%;
  //>>>>>>> master-merge-EV1285BBDesktopNewUI
  right: 0.5%;
  z-index: 1 !important;
  cursor: pointer;
  position: fixed !important;
}

@media (min-width: 1280px) {
  .responsive-video {
    height: 310px;
    width: 100%;
  }
}

@media (max-width: 1279px) {
  .responsive-video {
    height: 310px;
  }
}

@media (max-width: 1023px) {
  .responsive-video {
    height: 250px;
  }
  .wofClass {
    bottom: 20%;
    right: 0.5%;
    z-index: 2 !important;
    cursor: pointer;
    position: fixed !important;
  }
}

@media (max-width: 800px) {
  .responsive-video {
    height: 200px;
    width: 100%;
  }
}

@media (max-width: 610px) {
  .responsive-video {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .responsive-video {
    height: 130px;
    width: 100%;
  }
}

@media (max-width: 430px) {
  .responsive-video {
    height: 110px;
    width: 100%;
  }
}

@media (max-width: 360px) {
  .responsive-video {
    height: 100px;
    width: 100%;
  }
}
</style>
