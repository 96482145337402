<template>
  <div class="home-banner-box banner-content">
    <v-row no-gutters class="hidden-md-and-up">
      <v-col cols="12">
        <v-carousel class="home-banner-carousel-mobile hidden-md-and-up" hide-delimiters cycle interval="10000" height="auto">
          <v-carousel-item v-for="(banner, i) in bannerContent" :key="i">
            <v-img :src="`${banner.mobile_banner}`" @click="callBannerAction(banner)" :aspect-ratio="414 / 184"></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <!-- <app-sport-casino-holder></app-sport-casino-holder> -->
    </v-row>
    <div v-if="$vuetify.breakpoint.mdAndUp" class="banner-height hidden-sm-and-down">
      <v-carousel
        v-if="$vuetify.breakpoint.mdAndUp"
        cycle
        height="100%"
        hide-delimiter-background
        :hide-delimiters="false"
        delimiter-icon="mdi-minus"
        :show-arrows="false"
        interval="5000"
        class="home-banner-carousel"
        ref="carousel"
      >
        <v-carousel-item v-for="(banner, i) in bannerContent" :key="i">
          <img class="desktop-banner" :src="`${banner.desktop_banner}`" :alt="banner.desc" @click="callBannerAction(banner)" />
        </v-carousel-item>
      </v-carousel>
      <v-btn icon @click="prevBanner" class="prev-btn" style="color: #000 !important;">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon @click="nextBanner" class="next-btn" style="color: #000 !important;">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <v-row no-gutters style="padding: 1% 12%;" class="marquee-row">
      <marquee bgcolor="#333333" class="marquee">
        <template v-for="announcement in filteredAnnouncement">
          <span :key="announcement.id" class="pr-6 mr-6">
            <label class="d-inline-block home-announcment-content" style="color: #FFCE01;">
              <!--                      <span class="font-weight-bold">{{ $t(`label.annoucementHead`) }}</span>-->
              {{ announcement.title }}
            </label>
            <label class="d-inline-block home-announcment-content white--text" v-html="announcement.message != null ? ' :' + announcement.message.replace(/<[p>]*>/g, ' ') : ''"></label>
          </span>
        </template>
      </marquee>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.mdAndUp && !isLoggedIn" no-gutters class="home-page-create-account-container" style="margin: 0 12%;">
      <v-img class="" :src="`${mediaUrl}/banner/register_banner_home.jpg`" @click="naviToRegister"></v-img>
      <!--      <v-col cols="4" class="content-inside pa-5 content-border-left" @click="naviToRegister">-->
      <!--        <v-row no-gutters class="d-flex justify-center align-center">-->
      <!--          <v-col cols="3" class="d-flex justify-end">-->
      <!--            <img src="/static/image/homepage/createAccount.png" class="homePageJoinIcon" />-->
      <!--          </v-col>-->
      <!--          <v-col cols="9" style="padding-left: 10px;">-->
      <!--            <v-row no-gutters class="home-header-text">-->
      <!--              {{ $t(`label.homePageContent1`) }}-->
      <!--            </v-row>-->
      <!--            <v-row no-gutters class="home-description-text">-->
      <!--              {{ $t(`label.homePageContent1a`) }}-->
      <!--            </v-row>-->
      <!--          </v-col>-->
      <!--        </v-row>-->
      <!--      </v-col>-->
      <!--      <v-col cols="4" class="content-inside pa-5" @click="naviToDeposit">-->
      <!--        <v-row no-gutters class="d-flex justify-center align-center">-->
      <!--          <v-col cols="3" class="d-flex justify-end">-->
      <!--            <img src="/static/image/homepage/deposit.png" class="homePageJoinIcon" />-->
      <!--          </v-col>-->
      <!--          <v-col cols="9" style="padding-left: 10px;">-->
      <!--            <v-row no-gutters class="home-header-text">-->
      <!--              {{ $t(`label.homePageContent2`) }}-->
      <!--            </v-row>-->
      <!--            <v-row no-gutters class="home-description-text">-->
      <!--              {{ $t(`label.homePageContent2a`) }}-->
      <!--            </v-row>-->
      <!--          </v-col>-->
      <!--        </v-row>-->
      <!--      </v-col>-->
      <!--      <v-col cols="4" class="content-inside pa-5 content-border-right" @click="naviToRegister">-->
      <!--        <v-row no-gutters class="d-flex justify-center align-center">-->
      <!--          <v-col cols="3" class="d-flex justify-end">-->
      <!--            <img src="/static/image/homepage/reward.png" class="homePageJoinIcon" />-->
      <!--          </v-col>-->
      <!--          <v-col cols="9" style="padding-left: 10px;">-->
      <!--            <v-row no-gutters class="home-header-text">-->
      <!--              {{ $t(`label.homePageContent3`) }}-->
      <!--            </v-row>-->
      <!--            <v-row no-gutters class="home-description-text">-->
      <!--              {{ $t(`label.homePageContent3a`) }}-->
      <!--            </v-row>-->
      <!--          </v-col>-->
      <!--        </v-row>-->
      <!--      </v-col>-->
    </v-row>
  </div>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { SHARED } from '@/constants/constants'
import { uiHelper } from '@/util'
import { DevicePlatform } from '@/constants/enums'
import axios from 'axios'
import AppSportCasinoHolder from '@/components/home/SportCasinoHolder.vue'
import { CMS_BANNER_ACTIVE } from '@/store/cms.module'

export default {
  name: 'appHomeBanner',
  props: {
    filteredAnnouncement: {
      type: Array,
      required: true,
      default: []
    }
  },
  data: () => ({
    routeName: ROUTE_NAME,
    banners: [],
    mobilebanners: [],
    bannerIndex: 0,
    currentLanguage: uiHelper.getLanguage(),
    mediaUrl: SHARED.MEDIA_SERVER_URL
  }),
  components: {
    AppSportCasinoHolder
  },
  computed: {
    bannerContent() {
      return this.$store.state.cms.bannerContent
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
  },
  created() {
    this.getCmsBanner()
    // this.generateDesktopBanner()
    // this.generateMobileBanner()
  },
  methods: {
    prevBanner() {
      this.$refs.carousel.prev()
    },
    nextBanner() {
      this.$refs.carousel.next()
    },
    naviToRegister() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER,
        params: {
          lang: this.$route.params.lang
        }
      })
    },
    naviToDeposit() {
      if (!this.isLoggedIn) {
        this.$parent.goToLoginPage()
      } else {
        this.$router.push({
          name: ROUTE_NAME.DEPOSIT,
          params: {
            lang: this.$route.params.lang
          }
        })
      }
    },
    callBannerAction(action) {
      try {
        let s = action.action.replaceAll("'", '"')
        let jsonData = JSON.parse(s)
        this.bannerAction({
          action: jsonData['action'],
          action_url: action.action_url,
          bonusId: ''
        })
      } catch (err) {
        // this.bannerAction({
        //   'action': 'promotionpage',
        //   'bonusId': ''
        // })
      }
    },
    getCmsBanner() {
      let obj = {
        language: uiHelper.getLanguage()
      }
      this.$store.dispatch(`${CMS_BANNER_ACTIVE}`, {
        obj
      })
    },
    generateDesktopBanner() {
      axios({
        method: 'GET',
        url: SHARED.DESKTOP_BANNER_JSON_URL,
        responseType: 'json'
      }).then(response => {
        if (response.status == 200) {
          response.data.forEach(banner => {
            this.banners.push({
              src: banner.src,
              action: banner.action,
              bonusId: banner.bonusId
            })
          })
        }
      })
    },
    generateMobileBanner() {
      axios({
        method: 'GET',
        url: SHARED.MOBILE_BANNER_JSON_URL,
        responseType: 'json'
      }).then(response => {
        if (response.status == 200) {
          response.data.forEach(banner => {
            this.mobilebanners.push({
              src: banner.src,
              action: banner.action
            })
          })
        }
      })
    },
    bannerAction(banner) {
      let action = banner.action
      let action_url = banner.action_url
      let bonusId = banner.bonusId
      switch (action.toLowerCase()) {
        case 'register':
          if (!this.isLoggedIn) {
            this.$parent.goToRegisterPage()
          } else {
            this.$router.push({
              name: ROUTE_NAME.PROMOTION
            })
          }
          break
        case 'livecasinopage':
          this.$router.push({
            name: ROUTE_NAME.CASINO
          })
          break
        case 'urlpage':
          window.open(action_url, '_blank')
          break
        case 'sportsbookpage':
          this.$router.push({
            name: ROUTE_NAME.SPORTSBOOK
          })
          break
        case 'paynowdeposit':
          if (!this.isLoggedIn) {
            this.$parent.goToLoginPage()
          } else {
            this.$router.push({
              name: ROUTE_NAME.DEPOSIT
            })
          }
          break
        case 'rewards':
          this.$router.push({
            name: ROUTE_NAME.REWARD_STORE_ITEM,
            params: {
              type: 'luckyspin'
            }
          })
          break
        case 'promotionpage':
          this.$router.push({
            name: ROUTE_NAME.PROMOTION
          })
          break
        case 'slotpage':
          this.$router.push({
            name: ROUTE_NAME.SLOT
          })
          break
        case 'affiliatepage':
          this.$router.push({
            name: ROUTE_NAME.AFFILIATE_HOME
          })
          break
        case 'launchmjsgame':
          if (!this.isLoggedIn) {
            this.$parent.goToLoginPage()
          } else {
            if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
              let routeData = this.$router.resolve({
                name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
                params: {
                  provider: 'mjs',
                  type: 'sb'
                },
                query: {
                  demo: 0
                }
              })
              uiHelper.openNewTab(routeData.href, 'Game Play Lobby', 1200, 900)
            } else {
              this.$router.push({
                name: ROUTE_NAME.SPORTSBOOK_BY_PROVIDER,
                params: {
                  provider: 'mjs'
                }
              })
            }
          }
          break
      }
    }
  }
}
</script>

<style lang="scss">
.marquee {
  width: 100%;
  border-radius: 8px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homePageJoinIcon {
  width: 80px;
  height: 80px;
}
.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}
.content-border-left {
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
}
.content-border-right {
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
}
.home-header-text {
  font-weight: 500;
  font-size: 18px;
}

.home-description-text {
  font-size: 16px;
  font-family: 'Heebo-Regular', sans-serif;
}

.home-page-create-account-container {
  border-radius: 12px;
  .content-inside {
    background-color: #f3f5f6;
  }
}

.apply-button {
  flex-grow: 1;
  width: 120px;
  height: 90px;
  padding: 9px !important;

  .v-btn__content {
    color: black;
  }
}

.banner-overlay {
  height: auto;
  width: 35%;
  display: block;
  margin-left: auto;
}

.home-announcment-content span {
  color: #000 !important;
}

.home-banner-box {
  height: auto;
  position: relative;

  label {
    color: #000000;
  }

  button {
    color: #000000de !important;
  }

  .home-banner-carousel-mobile {
    .v-image {
      cursor: pointer;
    }
  }

  .home-banner-carousel {
    // border-radius: 40px;
    // height: 100% !important;
    width: 100%;

    .v-window__container {
      height: 100% !important;
    }

    .v-image {
      height: 100% !important;
      cursor: pointer;
    }

    .v-carousel__controls {
      bottom: 16px;
    }
  }
}

.home-announcment-content {
  font-size: 14px;
}

@media (max-width: 1300px) {
  .homePageJoinIcon {
    width: 60px;
    height: 60px;
  }
  .home-header-text {
    font-size: 16px;
  }
  .home-description-text {
    font-size: 14px;
  }
}
@media (max-width: 1100px) {
  .homePageJoinIcon {
    width: 55px;
    height: 55px;
  }
  .home-header-text {
    font-size: 14px;
  }
  .home-description-text {
    font-size: 12px;
  }
}
@media (max-width: 959px) {
  .banner-overlay {
    height: auto;
    width: 50%;
    display: block;
    margin-left: auto;
  }
  .home-announcment-content {
    font-size: 12px;
  }
}

.banner-content {
  position: relative;

  .banner-height {
    overflow: hidden;
    height: 20.8vw;
  }

  button {
    color: #ffffff !important;
    margin: 0px;
    padding: 0px;
  }

  .home-banner-carousel {
    cursor: pointer;
  }

  .desktop-banner {
    aspect-ratio: 1900/400;
    background-position-x: center;
    background-size: cover;
    width: 100%;
  }

  .home-banner-carousel-mobile {
    .v-image {
      cursor: pointer;
    }
  }

  .banner-overlay {
    height: auto;
    width: 35%;
    display: block;
    margin-left: auto;
  }
}
.prev-btn,
.next-btn {
  background-color: #fff;
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  z-index: 10;
}
@media (max-width: 959px) {
  .marquee-row {
    padding: 0px !important;
  }
  .marquee {
    border: none;
    border-radius: 0px !important;
    background-color: #3a3a3a !important;
  }
  .home-banner-box label {
    color: #fddd04;
  }
  .home-announcment-content span {
    color: #fddd04 !important;
  }
}
</style>
