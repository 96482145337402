<template>
  <div :class="$vuetify.breakpoint.mdAndDown ? 'pt-0 pb-4' : 'pb-4'" class="game-menu">
    <div>
      <!-- mobile view gametype card   -->
      <v-row no-gutters id="game-menu-full" class="game-menu-wrapper pb-2 hidden-md-and-up">
        <v-col cols="12" class="d-flex">
          <!-- homepage hot games          -->
          <div class="mx-auto" v-if="this.storageHomepageHotGames != ''">
            <v-card
              text
              :ripple="false"
              depressed
              :class="['game-menu-content font-weight-bold', [$vuetify.breakpoint.mdAndDown ? 'caption' : 'subtitle-1'], [dActiveGameType == 'homepageHot' ? 'selected' : '']]"
              @click="selectGameType('', -1)"
            >
              <div class="game-menu-image" :style="`--src: url('/static/svg/gameTabHolder/homepageHot.svg')`">
                <img :url="`/static/svg/mobileMenu/homepageHot.png`" class="side-menu-icon" />
              </div>
              <div class="game-menu-title">{{ $t(`label.homepageHot`) }}</div>
            </v-card>
          </div>
          <!--  game types       -->
          <div class="mx-auto" v-for="(gameType, index) in storageGameProviderType" :key="gameType.type" @click="selectGameType(gameType, index)">
            <v-card
              text
              dark
              :ripple="false"
              depressed
              @click="selectGameType(gameType, index)"
              :class="['game-menu-content font-weight-bold', [$vuetify.breakpoint.mdAndDown ? 'caption' : 'subtitle-1'], [dActiveGameType == gameType.type ? 'selected' : '']]"
            >
              <!--        <img :src="`/static/svg/gameTabHolder/${gameType.type}.svg`" class="side-menu-icon game-menu-image" />-->
              <div class="game-menu-image" :style="`--src: url('/static/svg/gameTabHolder/${gameType.type}.svg')`">
                <img :url="`/static/svg/mobileMenu/${gameType.type}.png`" class="side-menu-icon" />
              </div>
              <div class="game-menu-title">{{ $t(`label.${gameType.type}_short`) }}</div>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <!--      <v-divider></v-divider>-->
      <!--  mobile view homepage hot games card    -->
      <v-row class="mt-0 hidden-md-and-up mobile-provider-card-row left-right-gap" v-if="dActiveGameType == HomePageHotString">
        <v-col
          class="mobile-provider-card-item"
          :class="`gT_${HomePageHotString}`"
          v-for="(hotGames, i) in storageHomepageHotGames"
          :key="hotGames.code"
          @click="!isGameMaintenance(hotGames.type, hotGames.provider) ? launchGame(hotGames, false) : {}"
        >
          <v-img
            :src="hotGames.imageCdn == null ? `${mediaUrl}/game_icons/en/${hotGames.provider}/${hotGames.code}_${hotGames.id}.jpg` : `${hotGames.imageCdn}`"
            class="banner-img"
            :class="['homepageHot_img']"
            cover
            :aspect-ratio="BanneAspRatio"
          >
            <v-overlay v-if="isGameMaintenance(hotGames.type, hotGames.provider)" absolute opacity="0.8" class="gameMaintenanceOverlay">
              <label class="yellow--text text-uppercase font-weight-bold">{{ $t(`label.underMaintenance`) }}</label>
            </v-overlay>
            <img src="/static/image/other/hot-icon.png" class="img-hot-home pos_right_top" />
          </v-img>
          <!--        <span v-if="dActiveGameType == 'rng'" class="rng_providerLabel">{{ $t(`label.custom_${provider.providerCode}`) }}</span>-->
        </v-col>

        <div id="autoFillEle1" class="mobile-provider-card-item gT_homepageHot" style="max-height: 0px !important">
          <v-img max-height="0" class=" banner-img"></v-img>
        </div>
        <div id="autoFillEle2" class="mobile-provider-card-item gT_homepageHot" style="max-height: 0px !important">
          <v-img max-height="0" class=" banner-img"></v-img>
        </div>
      </v-row>

      <!-- mobile view game providers card   -->
      <v-row
        v-else
        class="mt-0 hidden-md-and-up mobile-provider-card-row"
        style="padding-bottom: 10px"
        :class="this.dActiveGameType == 'ld' || this.dActiveGameType == 'rng' || this.dActiveGameType == 'crash' ? 'ld-gap' : ''"
      >
        <v-col
          class="mobile-provider-card-item"
          :class="`gT_${dActiveGameType}`"
          v-for="(provider, i) in gameContainer1.providers"
          :key="provider.providerCode"
          @click="!isGameMaintenance(gameContainer1.type, provider.providerCode) ? accessGame(gameContainer1.type, provider.providerCode) : {}"
        >
          <!--          <v-img-->
          <!--            :src="'/static/image/mobileBanner/' + dActiveGameType + '_' + provider.providerCode.toLowerCase() + '.jpg'"-->
          <!--            class="banner-img"-->
          <!--            :aspect-ratio="dActiveGameType == 'ld' ? ldAspRatio : dActiveGameType == 'rng' ? 1 / 1 : 0"-->
          <!--          >-->
          <!--            <v-overlay v-if="isGameMaintenance(gameContainer1.type, provider.providerCode)" absolute opacity="0.8" class="gameMaintenanceOverlay">-->
          <!--              <label class="yellow&#45;&#45;text text-uppercase font-weight-bold">{{ $t(`label.underMaintenance`) }}</label>-->
          <!--            </v-overlay>-->
          <!--          </v-img>-->

          <!--          -->
          <v-img
            :src="
              dActiveGameType === 'cricket'
                ? `${mediaUrl}/mobileBanner/${dActiveGameType}_${provider.providerCode.toLowerCase()}_${currency}.jpg`
                : `${mediaUrl}/mobileBanner/${dActiveGameType}_${provider.providerCode.toLowerCase()}.jpg`
            "
            class="banner-img"
            :class="[dActiveGameType == 'rng' || dActiveGameType == 'crash' ? 'sqrBox_img' : '']"
            contain
            :aspect-ratio="BanneAspRatio"
          >
            <v-overlay v-if="isGameMaintenance(gameContainer1.type, provider.providerCode)" absolute opacity="0.8" class="gameMaintenanceOverlay">
              <label class="yellow--text text-uppercase font-weight-bold">{{ $t(`label.underMaintenance`) }}</label>
            </v-overlay>
            <img src="/static/image/other/hot-icon.png" class="img-hot-home pos_right_top" v-if="isHotProvider(provider.providerCode)" />
            <img src="/static/image/other/new_icon.png" class="img-new-home pos_right_top" v-if="isNewProvider(provider.providerCode)" />
          </v-img>
          <span v-if="dActiveGameType == 'rng'" class="rng_providerLabel">{{ $t(`label.custom_${provider.providerCode}`) }}</span>

          <!--          -->
          <!--          <v-img src="/static/image/banner/downloadClient/bb88_downloadnow_appbanner_mobile.jpg" class="hidden-md-and-up banner-img" ></v-img>-->

          <!--                  {{ provider.providerCode.toUpperCase() }}-->
        </v-col>
        <div
          id="autoFillEle1"
          class="mobile-provider-card-item gT_rng"
          v-if="dActiveGameType == 'rng' || dActiveGameType == 'crash' || dActiveGameType == 'homepageHot'"
          style="max-height: 0px !important"
        >
          <v-img max-height="0" class=" banner-img"></v-img>
        </div>
        <div
          id="autoFillEle2"
          class="mobile-provider-card-item gT_rng"
          v-if="dActiveGameType == 'rng' || dActiveGameType == 'crash' || dActiveGameType == 'homepageHot'"
          style="max-height: 0px !important"
        >
          <v-img max-height="0" class=" banner-img"></v-img>
        </div>
        <div
          id="autoFillEle3"
          class="mobile-provider-card-item gT_rng"
          v-if="dActiveGameType == 'rng' || dActiveGameType == 'crash' || dActiveGameType == 'homepageHot'"
          style="max-height: 0px !important"
        >
          <v-img max-height="0" class=" banner-img"></v-img>
        </div>
        <div
          id="ldAutoFillEle4"
          class="mobile-provider-card-item gT_ld"
          v-if="dActiveGameType == 'ld' || dActiveGameType == 'table' || dActiveGameType == 'homepageHot'"
          style="max-height: 0px !important"
        >
          <v-img max-height="0" class=" banner-img"></v-img>
        </div>
      </v-row>
    </div>
    <!-- desktop view hotgame   -->
    <v-row
      no-gutters
      class="mt-0 hidden-sm-and-down mobile-provider-card-row d-flex justify-start align-center"
      v-if="dActiveGameType == HomePageHotString && !$vuetify.breakpoint.mdAndDown"
      style="gap: 2.2%"
    >
      <v-col cols="12" class="title_hotGameDesktop">
        {{ $t(`label.hotGames`) }}
      </v-col>
      <v-row no-gutters class="align-center" style="width: 100%">
        <v-col class="desktop-provider-card-item pt-3" :class="`gT_${HomePageHotString}`" v-for="(hotGames, i) in storageHomepageHotGames" :key="hotGames.code">
          <v-col class="pa-0" style="width: 95%;">
            <v-col cols="12" class="pa-0">
              <v-img
                :src="hotGames.imageCdn == null ? `${mediaUrl}/game_icons/en/${hotGames.provider}/${hotGames.code}_${hotGames.id}.jpg` : `${hotGames.imageCdn}`"
                class="banner-img"
                :class="['homepageHot_img']"
                cover
                :aspect-ratio="BanneAspRatio"
              >
                <v-overlay v-if="!isGameMaintenance(hotGames.type, hotGames.provider)" absolute opacity="0" class="play-overlay">
                  <!-- Button -->
                  <v-btn icon class="playBtn" @click="launchGame(hotGames, false)">
                    <v-avatar class="playBtnIcon" :size="$vuetify.breakpoint.mdAndDown ? '48' : '60'">
                      <img src="/static/svg/play_btn.svg" @mouseover="hoverPlayButton($event.currentTarget, true)" @mouseleave="hoverPlayButton($event.currentTarget, false)" alt />
                    </v-avatar>
                  </v-btn>
                </v-overlay>
                <v-overlay v-if="isGameMaintenance(hotGames.type, hotGames.provider)" absolute opacity="0.8" class="gameMaintenanceOverlay">
                  <label class="yellow--text text-uppercase font-weight-bold">{{ $t(`label.underMaintenance`) }}</label>
                </v-overlay>
                <img src="/static/image/other/hot-icon.png" class="img-hot-home pos_right_top" />
              </v-img>
            </v-col>
            <v-col cols="12" class="pa-0">
              <span>{{ getGameName(hotGames.locale) }}</span>
            </v-col>
            <v-col cols="12" class="pa-0">
              <span class="rng_providerLabel">{{ $t(`label.hotGames_${hotGames.provider}`).toUpperCase() }}</span>
            </v-col>
          </v-col>
        </v-col>
      </v-row>

      <div id="autoFillEle1" class="mobile-provider-card-item gT_homepageHot" style="max-height: 0px !important">
        <v-img max-height="0" class=" banner-img"></v-img>
      </div>
      <div id="autoFillEle2" class="mobile-provider-card-item gT_homepageHot" style="max-height: 0px !important">
        <v-img max-height="0" class=" banner-img"></v-img>
      </div>
    </v-row>
    <!-- desktop view gametype card   -->
    <!--    <v-row class="hidden-sm-and-down" no-gutters justify="space-around">-->
    <!--      <v-col cols="12">-->
    <!--        <v-card class="game-card-menu" style="overflow-x: auto; flex-wrap: nowrap">-->
    <!--          <v-row no-gutters justify="space-around">-->
    <!--            <v-btn-->
    <!--              v-for="(gameType, index) in storageGameProviderType"-->
    <!--              :key="gameType.type"-->
    <!--              @click="selectGameType(gameType, index)"-->
    <!--              class="pt-2 pb-1 px-0 page-navigator-button"-->
    <!--              min-width="45px"-->
    <!--              height="auto"-->
    <!--              text-->
    <!--              :ripple="false"-->
    <!--              light-->
    <!--              depressed-->
    <!--            >-->
    <!--              <v-row no-gutters>-->
    <!--                <v-col cols="12" class="py-0 text-center">-->
    <!--                  <v-divider v-show="gameType.selected" class="game-menu-divider mx-2"></v-divider>-->
    <!--                </v-col>-->
    <!--                <v-col cols="12" class="px-0 pb-1 text-center">-->
    <!--                  <label class="game-menu-title px-2 font-weight-bold">{{ $t(`label.${gameType.type}_short`) }}</label>-->
    <!--                </v-col>-->
    <!--              </v-row>-->
    <!--            </v-btn>-->
    <!--          </v-row>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!-- desktop view game providers card   -->
    <!--    <v-row class="mt-0 pt-0 hidden-sm-and-down " justify="space-around">-->
    <!--      <v-col md="6" sm="12" class="pt-0">-->
    <!--        <v-card :class="['game-card-container', lengthOfContainer()]">-->
    <!--          <v-img class="white&#45;&#45;text align-end pa-0" :aspect-ratio="28 / 9"-->
    <!--                 :src="`${mediaUrl}/vendor-banner/${gameContainer1.type}_banner.jpg`"></v-img>-->
    <!--          <v-card-text class="game-card-box">-->
    <!--            <v-row>-->
    <!--              <v-col class="game-card-box-item text-center pa-2" v-for="provider in gameContainer1.providers"-->
    <!--                     :key="provider.providerCode" cols="3">-->
    <!--                <div>-->
    <!--                  &lt;!&ndash; <v-img src="https://cdn.vuetifyjs.com/images/cards/sun.png" alt="Sunny image" width=auto></v-img> &ndash;&gt;-->
    <!--                  &lt;!&ndash; <v-img :src="`${mediaUrl}/vendor-card/${gameContainer1.type}/${provider.providerCode}_avatar.png`" width=auto alt/> &ndash;&gt;-->
    <!--                  <v-img-->
    <!--                    @click="!isGameMaintenance(gameContainer1.type, provider.providerCode) ? accessGame(gameContainer1.type, provider.providerCode) : {}"-->
    <!--                    contain-->
    <!--                    class="game-card-image-avatar"-->
    <!--                    :src="`${mediaUrl}/vendor-card/${gameContainer1.type}/${provider.providerCode}_avatar.png`"-->
    <!--                    :alt="'babu88-' + provider.providerCode"-->
    <!--                    width="auto"-->
    <!--                  >-->
    <!--                    <v-overlay v-if="isGameMaintenance(gameContainer1.type, provider.providerCode)" absolute-->
    <!--                               opacity="0.8" class="gameMaintenanceOverlay">-->
    <!--                      <label class="yellow&#45;&#45;text text-uppercase font-weight-bold">{{-->
    <!--                          $t(`label.underMaintenance`)-->
    <!--                        }}</label>-->
    <!--                    </v-overlay>-->
    <!--                    <img src="/static/image/other/hot-icon.png" class="img-hot-home pos_right_top"-->
    <!--                         v-if="isHotProvider(provider.providerCode)" />-->
    <!--                    <img src="/static/image/other/new_icon.png" class="img-new-home pos_right_top"-->
    <!--                         v-if="isNewProvider(provider.providerCode)" />-->
    <!--                  </v-img>-->
    <!--                  <p class="mt-lg-2">-->
    <!--                    <label class="black&#45;&#45;text text-capitalize text-center holder_text">{{-->
    <!--                        $t(`label.custom_${provider.providerCode}`)-->
    <!--                      }}</label>-->
    <!--                  </p>-->
    <!--                </div>-->
    <!--              </v-col>-->
    <!--            </v-row>-->
    <!--          </v-card-text>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--      <v-col cols="6" class="pt-0">-->
    <!--        <v-card-->
    <!--          :class="[-->
    <!--            'game-card-container',-->
    <!--            gameContainer2.providers.length > 8 || gameContainer1.providers.length > 8 ? 'three' : gameContainer1.providers.length > 4 || gameContainer2.providers.length > 4 ? 'two' : 'one'-->
    <!--          ]"-->
    <!--        >-->
    <!--          <v-img class="white&#45;&#45;text align-end pa-0" :aspect-ratio="28 / 9"-->
    <!--                 :src="`${mediaUrl}/vendor-banner/${gameContainer2.type}_banner.jpg`"></v-img>-->
    <!--          <v-card-text class="game-card-box">-->
    <!--            <v-row align="center">-->
    <!--              <v-col class="game-card-box-item text-center pa-2" v-for="provider in gameContainer2.providers"-->
    <!--                     :key="provider.providerCode" cols="3">-->
    <!--                <div>-->
    <!--                  &lt;!&ndash;                  <v-img class="game-card-image-avatar" v-if="gameContainer2.type == 'fishing'"&ndash;&gt;-->
    <!--                  &lt;!&ndash;                         src="https://cdn.vuetifyjs.com/images/cards/sun.png" alt="babu88" width=auto></v-img>&ndash;&gt;-->
    <!--                  <v-img-->
    <!--                    @click="!isGameMaintenance(gameContainer2.type, provider.providerCode) ? accessGame(gameContainer2.type, provider.providerCode) : {}"-->
    <!--                    class="game-card-image-avatar"-->
    <!--                    :src="`${mediaUrl}/vendor-card/${gameContainer2.type}/${provider.providerCode}_avatar.png`"-->
    <!--                    :alt="'babu88-' + provider.providerCode"-->
    <!--                    width="auto"-->
    <!--                  >-->
    <!--                    <v-overlay v-if="isGameMaintenance(gameContainer2.type, provider.providerCode)" absolute-->
    <!--                               opacity="0.8" class="gameMaintenanceOverlay">-->
    <!--                      <label class="yellow&#45;&#45;text text-uppercase font-weight-bold">{{-->
    <!--                          $t(`label.underMaintenance`)-->
    <!--                        }}</label>-->
    <!--                    </v-overlay>-->
    <!--                    <img src="/static/image/other/hot-icon.png" class="img-hot-home pos_right_top"-->
    <!--                         v-if="isHotProvider(provider.providerCode)" />-->
    <!--                    <img src="/static/image/other/new_icon.png" class="img-new-home pos_right_top"-->
    <!--                         v-if="isNewProvider(provider.providerCode)" />-->
    <!--                  </v-img>-->
    <!--                  <p class="mt-lg-2">-->
    <!--                    <label class="black&#45;&#45;text text-capitalize text-center holder_text">{{-->
    <!--                        $t(`label.custom_${provider.providerCode}`)-->
    <!--                      }}</label>-->
    <!--                  </p>-->
    <!--                </div>-->
    <!--              </v-col>-->
    <!--              &lt;!&ndash; <v-col class="game-card-box-item" v-for="provider in 6" :key="provider" cols="3">-->
    <!--                      <div>-->
    <!--                          <v-img src="https://cdn.vuetifyjs.com/images/cards/sun.png" alt="Sunny image" width=auto></v-img>-->
    <!--                          <v-img :aspect-ratio="311/234" class="game-card-image-avatar"-->
    <!--                     :src="`${mediaUrl}/vendor-card/${gameContainer2.type}/${provider.providerCode}_avatar.png`"-->
    <!--                     alt/>-->
    <!--                      </div>-->
    <!--                  </v-col> &ndash;&gt;-->
    <!--            </v-row>-->
    <!--          </v-card-text>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
  </div>
</template>

<script>
import { uiHelper } from '@/util'
import { CACHE_KEY } from '@/constants/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import { DevicePlatform } from '@/constants/enums'
import { SHARED } from '@/constants/constants'
import { SEMI_TRANSFER } from '@/store/transfer.module'
import { GAME_HOMEPAGE_HOT, GAME_PROVIDERS_TYPE_CONFIG } from '@/store/game.module'

export default {
  name: 'appGameCard',
  // props: {
  //     selectionNavigator: {
  //         type: String,
  //         required: true,
  //         default: 'all'
  //     },
  //     openLoginDialog: {
  //         type: Function,
  //         required: true,
  //         default: () => {}
  //     },
  //     gameType: {
  //         type: String,
  //         required: true,
  //         default: () => ''
  //     }
  // },
  data: () => ({
    currency: uiHelper.getCurrency().toUpperCase(),
    ldAspRatio: 2 / 1,
    sportCricketTableAspRatio: 3 / 1,
    tableAspRatio: 2 / 1,
    slotAspRatio: 1 / 1,
    crashAspRatio: 1 / 1,
    routeName: ROUTE_NAME,
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    storageHomepageHotGames: uiHelper.setLocalStorage(`${CACHE_KEY.HOMEPAGE_HOTGAMELIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    selectedGameCard: '',
    gameContainer1: {},
    gameContainer2: {},
    dActiveGameType: 'ld',
    HomePageHotString: 'homepageHot',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  }),
  computed: {
    BanneAspRatio() {
      let _aspRatio = 1 / 1
      switch (this.dActiveGameType) {
        case 'ld':
          _aspRatio = this.ldAspRatio
          break
        case 'rng':
        case this.HomePageHotString:
          _aspRatio = this.slotAspRatio
          break
        case 'cricket':
          _aspRatio = 1200 / 400
          break
        case 'table':
          _aspRatio = this.tableAspRatio
          break
        case 'sb':
          _aspRatio = 1200 / 400 //temp set as 4:1, should be 3:1
          break
        case 'fishing':
          _aspRatio = 0
          break
        case 'lottery':
          _aspRatio = 0
          break
        case 'crash':
          _aspRatio = this.crashAspRatio
          break
      }
      return _aspRatio
    },
    ActiveMobileGameType() {
      return this.dActiveGameType
    },
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    calctab() {
      return this.text.replace('Lorem', 'tab2')
    },
    homepageHotGames() {
      return this.$store.state.game.HomepageHotGames
    }
  },
  watch: {
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
      this.selectGameType('', 0)
    },
    ActiveMobileGameType() {
      return this.dActiveGameType
    },
    homepageHotGames() {
      this.storageHomepageHotGames = this.$store.state.game.HomepageHotGames
    }
  },
  created() {
    this.selectGameType('', 0)
    this.getHomepageHotGames()
  },
  methods: {
    hoverPlayButton(element, mouseOver) {
      if (mouseOver) {
        element.src = '/static/svg/play_btn_hover.svg'
      } else {
        element.src = '/static/svg/play_btn.svg'
      }
    },
    hoverDemoButton(element, mouseOver) {
      if (mouseOver) {
        element.src = '/static/svg/demo_btn_hover.svg'
      } else {
        element.src = '/static/svg/demo_btn.svg'
      }
    },
    getGameName(gameLocale) {
      try {
        console.log(gameLocale.find(x => x.language == 'en').name)
        return gameLocale.find(x => x.language == this.currentLanguage).name
      } catch (error) {
        console.log(gameLocale.find(x => x.language == 'en').name)
        return gameLocale.find(x => x.language == 'en').name
      }
    },
    async directLaunchGame(gameInfo, isDemo) {
      let routeData = this.$router.resolve({
        name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
        params: {
          provider: gameInfo.provider,
          type: gameInfo.type,
          lang: this.$route.params.lang
        },
        query: {
          id: gameInfo.id,
          code: gameInfo.code,
          demo: isDemo ? 1 : 0
        }
      })
      try {
        this.$ga.event('Game', `${isDemo ? 'Demo' : 'Real'} Play`, `${gameInfo.provider.toUpperCase()} - Slot - ${gameInfo.locale.find(x => x.language == 'en').name}`, gameInfo.id)
      } catch (err) {
        console.log(err)
      }
      if (this.providerSelected == 'sb' || this.providerSelected == 'cricket') {
        window.open(routeData.href, '_blank')
        this.semiTransfer(gameInfo.provider)
      } else {
        window.open(routeData.href, '_blank')
        this.semiTransfer(gameInfo.provider).then(() => {})
      }
    },
    launchGame(gameInfo, isDemo) {
      if (!isDemo && !this.isLoggedIn) {
        this.$parent.goToLoginPage()
      } else {
        this.directLaunchGame(gameInfo, isDemo)
      }
      uiHelper.pushSportRadarRetargeting(gameInfo, gameInfo.type, gameInfo.provider)
    },
    getHomepageHotGames() {
      if (!this.storageHomepageHotGames) {
        let obj = {
          currency: uiHelper.getCurrency(),
          platform: uiHelper.getPlatform(),
          size: '',
          page: '',
          mode: 'All'
        }
        this.$store.dispatch(`${GAME_HOMEPAGE_HOT}`, { obj })
      }
    },
    getGameProviderTypeInfo() {
      let providerTypeConfigObj = {
        currency: uiHelper.getCurrency(),
        platform: uiHelper.getPlatform()
      }
      this.$store.dispatch(`${GAME_PROVIDERS_TYPE_CONFIG}`, { providerTypeConfigObj })
    },
    goToRegisterPage() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER,
        params: { lang: this.$route.params.lang }
      })
    },
    goToLoginPage() {
      this.$router.push({
        name: ROUTE_NAME.LOGIN,
        params: { lang: this.$route.params.lang }
      })
    },
    isNewProvider(provider) {
      let arr = ['aura', 'royal', 'booongo', 'haba', 'nolimit', 'one', 'pg', 'relax', 'netent']
      return arr.indexOf(provider.toLowerCase()) > -1
    },
    isHotProvider(provider) {
      let arr = ['jili', 'evo', 'betswiz', 'pp', 'sexy_v2', 'spg', 'ibc', 'smart', '9wicket']
      return arr.indexOf(provider.toLowerCase()) > -1
    },
    setActiveGameType(gameType, index) {
      this.dActiveGameType = gameType != undefined ? gameType.type : 'cricket'
    },
    lengthOfContainer() {
      let c1 = this.gameContainer1
      let c2 = this.gameContainer2
      if (c1 != null) {
        return c1.providers.length > 8 ? 'three' : c1.providers.length > 4 ? 'two' : 'one'
        if (c2 != null) {
          return c2.providers.length > 8 ? 'three' : c2.providers.length > 4 ? 'two' : 'one'
        }
      }
      return 'one'
    },
    openLoginDialog() {
      this.$parent.openLoginDialog()
    },
    tabfunc() {
      let t = this.tab.split('-')
      let index = parseInt(t[1]) + 1
      this.tab2 = 'tab-' + index
      console.log(this.tab2)
      console.log(this.tab)
    },
    selectGameType(gameType, index) {
      this.dActiveGameType = gameType != '' && gameType != undefined ? gameType.type : 'homepageHot'
      if (gameType == '' && index == -1) return

      if (this.storageGameProviderType) {
        if (this.$vuetify.breakpoint.mdAndUp) {
          if (index + 1 < this.storageGameProviderType.length) {
            this.gameContainer1 = this.storageGameProviderType[index]
            this.gameContainer2 = this.storageGameProviderType[index + 1]
          } else {
            this.gameContainer1 = this.storageGameProviderType[index - 1]
            this.gameContainer2 = this.storageGameProviderType[index]
          }
          _.forEach(this.storageGameProviderType, function(gameType) {
            gameType.selected = false
          })
          this.gameContainer1.selected = true
          this.gameContainer2.selected = true
        } else {
          this.gameContainer1 = this.storageGameProviderType[index]
          this.gameContainer2 = this.storageGameProviderType[index]
          _.forEach(this.storageGameProviderType, function(gameType) {
            gameType.selected = false
          })
          this.gameContainer1.selected = true
        }
      } else {
        this.getGameProviderTypeInfo()
      }
    },
    isGameMaintenance(gameType, providerCode) {
      if (
        this.storageGameProviderType == null ||
        this.storageGameProviderType.find(x => x.type == gameType) == undefined ||
        this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode) == undefined
      ) {
        return false
      } else {
        return this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode).isMaintenance
      }
    },
    async accessGame(type, providerCode) {
      let gaType = ''
      switch (type) {
        case 'sb':
          gaType = 'Sportsbook'
          break
        case 'ld':
          gaType = 'Casino'
          break
        case 'special':
          gaType = 'Special'
          break
        case 'crash':
          gaType = 'Crash'
          break
      }
      // if (!this.isLoggedIn && uiHelper.getPlatform() == DevicePlatform.MOBILE && type != 'rng' && type != 'special') {
      //     this.openLoginDialog()
      // }
      // else {
      if (type == 'rng') {
        this.$router.push({
          name: ROUTE_NAME.SLOT_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (type == 'fishing') {
        this.$router.push({
          name: ROUTE_NAME.FISHING_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (type == 'lottery') {
        this.$router.push({
          name: ROUTE_NAME.LOTTERY_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (type == 'table') {
        this.$router.push({
          name: ROUTE_NAME.TABLE_GAMES_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (type == 'special') {
        this.$router.push({
          name: ROUTE_NAME.SPECIAL_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (type == 'crash') {
        this.$router.push({
          name: ROUTE_NAME.CRASH_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else {
        if (this.isLoggedIn) {
          let routeData = this.$router.resolve({
            name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
            params: {
              provider: providerCode,
              lang: this.$route.params.lang,
              type: type
            },
            query: {
              demo: 0
            }
          })
          try {
            this.$ga.event('Game', 'Real Play', `${providerCode.toUpperCase()} - ${gaType}`)
          } catch (err) {
            console.log(err)
          }
          if (type == 'sb' || type == 'cricket') {
            await uiHelper.openNewTab(routeData.href, 'Game Play Lobby', 1440, 810)
            this.semiTransfer(providerCode)
          } else {
            this.semiTransfer(providerCode).then(() => {
              uiHelper.openNewTab(routeData.href, 'Game Play Lobby', 1440, 810)
            })
          }
          uiHelper.pushSportRadarRetargeting(null, type, providerCode)
        } else {
          this.goToLoginPage()
        }
      }
      //}
    },
    semiTransfer(providerCode) {
      let obj = {
        to_wallet: providerCode
      }
      return this.$store.dispatch(`${SEMI_TRANSFER}`, { obj })
    }
  }
}
</script>

<style lang="scss">
.playBtn {
  opacity: 0;
}
.banner-img:hover .play-overlay {
  //display: block;
  background-color: rgba(255, 255, 255, 0.65);
}
.banner-img:hover .playBtn {
  opacity: 1;
}
.title_hotGameDesktop {
  font-size: 25px;
}
.img-hot-home {
  width: 35px;
  animation: beat 1s infinite alternate;
}

.img-new-home {
  width: 35px;
  top: 7px;
  animation: beat 1s infinite alternate;
}

.pos_right_top {
  position: absolute !important;
  right: 4px;
  top: 5px;
}

.mobile-provider-card-row {
  justify-content: center;

  .v-image__image {
    //background-size: 100% 100%;
  }

  &.rng-warpper {
    gap: 0.5rem;
  }

  .rng_providerLabel {
    font-size: 0.7rem;
  }
}

.ld-gap {
  gap: 0.5rem;
  padding-top: 12px;
}

.left-right-gap {
  gap: 0.8rem 0.6rem;
}

.banner-img {
  border-radius: 10px;
}
.desktop-provider-card-item {
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-bottom: 1%;
  //margin-bottom: 20px;
  border-radius: 10px !important;
  //2 cols
  &.gT_homepageHot {
    min-width: 20%;
    max-width: 20%;
    height: 10%;
    flex-direction: column;
    padding: unset;
  }

  &.gT_ld {
    min-width: 47vw;
    max-width: 47vw;
    padding: 0 5px 5px 0 !important;
    height: auto !important;
  }

  //4 cols
  &.gT_rng {
    min-width: 22vw;
    max-width: 22vw;
    height: auto !important;
    flex-direction: column;
    padding: unset;
  }

  &.gT_crash {
    min-width: 22vw;
    max-width: 22vw;
    height: auto !important;
    flex-direction: column;
    padding: unset;
  }

  //single col
  &.gT_cricket {
    height: auto !important;
    min-width: 90vw;
    //height: 90px;
  }

  &.gT_sb {
    min-width: 90vw;
    height: auto !important;
    //height: 90px;
  }

  &.gT_table {
    min-width: 47vw;
    max-width: 47vw;
    padding: 0 5px 5px 0 !important;
    height: auto !important;
  }

  &.gT_fishing {
    min-width: 90vw;
    height: auto !important;
  }

  &.gT_lottery {
    min-width: 90vw;
    height: auto !important;
  }

  .sqrBox_img {
    height: auto;
    width: 100%;
  }

  .homepageHot_img {
    height: auto;
    width: 100%;
    max-height: 150px;
  }
}
.mobile-provider-card-item {
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-bottom: 20px;
  border-radius: 10px !important;
  //2 cols
  &.gT_homepageHot {
    min-width: 30vw;
    max-width: 30vw;
    height: auto !important;
    flex-direction: column;
    padding: unset;
  }

  &.gT_ld {
    min-width: 47vw;
    max-width: 47vw;
    padding: 0 5px 5px 0 !important;
    height: auto !important;
  }

  //4 cols
  &.gT_rng {
    min-width: 22vw;
    max-width: 22vw;
    height: auto !important;
    flex-direction: column;
    padding: unset;
  }

  &.gT_crash {
    min-width: 22vw;
    max-width: 22vw;
    height: auto !important;
    flex-direction: column;
    padding: unset;
  }

  //single col
  &.gT_cricket {
    height: auto !important;
    min-width: 90vw;
    //height: 90px;
  }

  &.gT_sb {
    min-width: 90vw;
    height: auto !important;
    //height: 90px;
  }

  &.gT_table {
    min-width: 47vw;
    max-width: 47vw;
    padding: 0 5px 5px 0 !important;
    height: auto !important;
  }

  &.gT_fishing {
    min-width: 90vw;
    height: auto !important;
  }

  &.gT_lottery {
    min-width: 90vw;
    height: auto !important;
  }

  .sqrBox_img {
    height: auto;
    width: 100%;
  }

  .homepageHot_img {
    height: auto;
    width: 100%;
  }
}

.game-menu {
  .div-gameTypeCard-wrapper {
  }

  .game-menu-wrapper {
    max-width: 1240px;
    white-space: nowrap;
    overflow: auto;
    display: flex;
    overflow-y: hidden;
    align-items: center;
    background-color: var(--v-text-base);
    margin: 10px 0 10px 0;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: space-evenly;
  }

  .game-menu-content {
    min-width: 85px;
    width: 88px;
    height: 88px;
    text-transform: capitalize;
    padding: 10px 0px !important;
    background-color: #333333;
    color: #ffffff;
    margin: 0 10px 3px 0px;

    .v-btn__content {
      display: flex;
      flex-direction: column;
    }

    //.game-menu-image {
    //  margin-left: 8px;
    //  width: 40px;
    //  height: 40px;
    //}
    .game-menu-image {
      background-color: #ffffff;
      -webkit-mask-image: var(--src);
      mask-image: var(--src);
      -webkit-mask-size: 100%;
      mask-size: 100%;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      width: 40px;
      height: 45px;
      margin-left: 8px;
    }

    .game-menu-title {
      margin-top: 8px;
      font-size: 12px;
      margin-left: 8px;
    }
  }
}

.game-menu {
  .v-btn {
    border-radius: unset;
  }

  .game-menu-content {
    &.selected {
      color: #000000;
      opacity: 1;
      background: var(--v-newMobilePrimary-base);

      .game-menu-image {
        background-color: #000000;
      }
    }
  }
}

.underline_bar {
  opacity: 0;
}

.game-card-menu {
  max-height: 80px;
  border-radius: 20px !important;

  .game-menu-title {
    font-size: 24px;
    text-transform: capitalize;

    &:hover {
      color: #0c2aab;
    }
  }

  .game-menu-divider {
    border: 2px solid #fddd04;
  }
}

.game-banner-text {
  font-size: 28px;

  p span {
    display: block;
  }
}

.game-card-container {
  //max-height: 530px;
  min-height: auto;
  max-height: max-content;
  border-radius: 20px !important;
}

.game-card-banner {
  height: 250px;
}

.icon_gameCard {
  fill: white;
  color: black;
  stroke: #9c9ff3;
  width: 30px;
  height: 33px;
  padding-top: 4px;
}

.home_slot_text {
  color: #9c9ff3;
  float: left;
  font-size: 26px !important;
}

.row_slots {
  position: relative;
}

.row_slots img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.card-hidden {
  display: none;
}

.game-card-box-container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 100vw;
}

.holder_text {
  font-size: 18px;
  display: block;
  line-height: 2;
}

.game-card-box {
  min-height: 452px;

  .game-card-box-item {
    cursor: pointer;
    display: inline-block;
    min-width: 105px;
    min-height: 105px;
    .game-card-image-avatar {
      border-radius: 10px;
      border: 1px solid transparent;

      .gameMaintenanceOverlay {
        border-radius: 20px;
      }
    }
  }

  .game-card-box-item:hover {
    .game-card-image-avatar {
      border: 5px solid #fddd04;
      border-radius: 30px;
    }
  }

  // .game-card-box-item:hover {
  //     .game-card-image-avatar {
  //         opacity: 0.4;
  //     }
  //     .vendor-info {
  //         opacity: 1;
  //         height: auto;
  //         width: auto;
  //         position: absolute;
  //         top: 50%;
  //         left: 50%;
  //         transform: translate(-50%, -50%);
  //     }
  //     .vendor-title {
  //         background: -webkit-linear-gradient(#FFA15C, #EDD763);
  //         -webkit-background-clip: text;
  //         -webkit-text-fill-color: transparent;
  //         text-shadow: 3px 3px 3px #FFFFFF;
  //     }
  // }
}

@media (max-width: 2560px) {
  .game-card-container {
    //max-height: 720px;
    min-height: auto;
    max-height: max-content;
    border-radius: 20px !important;
  }

  .game-card-container.three {
    //min-height: 1400px;
    min-height: auto;
    max-height: max-content;
  }

  .game-card-container.two {
    //min-height: 1080px;
    min-height: auto;
    max-height: max-content;
  }
}

@media (max-width: 1920px) {
  .game-card-box {
    min-height: 460px;
  }
  .game-card-container {
    //max-height: 540px;
    min-height: auto;
    max-height: max-content;
    border-radius: 20px !important;
  }
  .game-card-container.three {
    //min-height: 1080px;
    min-height: auto;
    max-height: max-content;
  }

  .game-card-container.two {
    //min-height: 820px;
    min-height: auto;
    max-height: max-content;
  }
}

@media (max-width: 1650px) {
  .game-card-box {
    min-height: 430px;
  }
  .game-card-container {
    //max-height: 490px;
    min-height: auto;
    max-height: max-content;
    border-radius: 20px !important;
  }
  .game-card-container.three {
    //min-height: 900px;
    min-height: auto;
    max-height: max-content;
  }

  .game-card-container.two {
    //min-height: 710px;
    min-height: auto;
    max-height: max-content;
  }
}

@media (max-width: 1500px) {
  .game-card-box {
    min-height: 380px;
  }
  .game-card-container {
    //max-height: 430px;
    min-height: auto;
    max-height: max-content;
    border-radius: 20px !important;
  }
  .game-card-container.three {
    //min-height: 850px;
    min-height: auto;
    max-height: max-content;
  }

  .game-card-container.two {
    //min-height: 660px;
    min-height: auto;
    max-height: max-content;
  }
}

@media (max-width: 1350px) {
  .game-card-box {
    min-height: 300px;
  }
  .game-card-container {
    //max-height: 360px;
    min-height: auto;
    max-height: max-content;
    border-radius: 20px !important;
  }
  .game-card-container.three {
    //min-height: 800px;
    min-height: auto;
    max-height: max-content;
  }

  .game-card-container.two {
    //min-height: 610px;
    min-height: auto;
    max-height: max-content;
  }
}

@media (max-width: 1280px) {
  .desktop-provider-card-item .homepageHot_img {
    max-height: 125px;
  }
  .game-card-box {
    min-height: 300px;
  }
  .game-card-container {
    //max-height: 360px;
    min-height: auto;
    max-height: max-content;
    border-radius: 20px !important;
  }

  .game-card-container.three {
    //min-height: 750px;
    min-height: auto;
    max-height: max-content;
  }

  .game-card-container.two {
    //min-height: 580px;
    min-height: auto;
    max-height: max-content;
  }
}

@media (max-width: 1024px) {
  .desktop-provider-card-item .homepageHot_img {
    max-height: 100px;
  }
  .game-card-box {
    min-height: 280px;
  }
  .game-card-container {
    //max-height: fit-content;
    min-height: auto;
    max-height: max-content;
    border-radius: 20px !important;
  }
  .game-card-container.three {
    //min-height: 450px;
    min-height: auto;
    max-height: max-content;
  }

  .game-card-container.two {
    //min-height: 380px;
    min-height: auto;
    max-height: max-content;
  }
}

@media (max-width: 959px) {
  .holder_text {
    font-size: 12px;
  }
  .game-card-banner {
    height: 120px;
  }
  .game-banner-text {
    font-size: 16px;
  }
  .game-card-box {
    min-height: 120px;

    .game-card-box-item {
      display: inline-block;
      min-width: 54px;
      min-height: 54px;
    }
  }
  .game-card-menu {
    max-height: 80px;
    border-radius: 20px !important;

    .game-menu-title {
      font-size: 14px;
      text-transform: capitalize;

      &:hover {
        color: #0c2aab;
      }
    }

    .game-menu-divider {
      border: 2px solid #fddd04;
    }
  }
}

@media (max-width: 699px) {
  .game-menu-wrapper {
    .game-menu-content {
      min-width: 70px !important;
      width: 70px !important;
      height: 73px !important;

      .game-menu-image {
        width: 35px !important;
        height: 39px !important;
      }

      .game-menu-title {
        margin-top: 3px !important;
      }
    }
  }
}

@media (max-width: 599px) {
  .pos_right_top {
    right: 3px;
    top: 3px;
  }

  .img-hot-home {
    width: 22px;
  }

  .img-new-home {
    width: 25px;
  }

  .holder_text {
    font-size: 8px;
  }
  .game-card-banner {
    height: 90px;
  }
  .game-banner-text {
    font-size: 16px;
  }
  .game-card-box {
    min-height: 100px;

    .game-card-box-item {
      display: inline-block;
      min-width: 54px;
      min-height: 54px;
      max-height: 140px;
    }
  }

  .game-menu-title {
    font-size: 10px !important;

    &:hover {
      color: #0c2aab;
    }
  }

  .game-card-menu {
    max-height: 80px;
    border-radius: 10px !important;

    .game-menu-divider {
      border: 2px solid #fddd04;
    }
  }
}

@media (max-width: 499px) {
  .game-card-box {
    .game-card-box-item {
      max-height: 103px;
    }
  }
}

@keyframes beat {
  to {
    transform: scale(1.15);
  }
}
</style>
